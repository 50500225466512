import { ReactNode } from 'react';
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import styles from '../styles/home.module.scss';
import json_home_en from '../jsons/en/home_en.json';
import json_home_pt from '../jsons/pt/home_pt.json';
import { classNames } from '../utils';
import { Tilt } from 'react-tilt'
import brunodImage from '../resources/images/brunod_circled.png';
import vascoImage from '../resources/images/vasco_circled.png';
import vitorImage from '../resources/images/vitor_circled.png';
import brunocImage from '../resources/images/brunoc_circled.png';
import gabrielaImage from '../resources/images/gabriela_circled.jpeg';
import vaniaImage from '../resources/images/vania_fernandes.jpg';
import luizImage from '../resources/images/luiz_circled.png';
import pedroImage from '../resources/images/pedro_circled.png';
import joaoImage from '../resources/images/brito_new_2.jpeg';
import larsImage from '../resources/images/lars_circled.png';
import joaoNImage from '../resources/images/neves_circled.png';
import hugoImage from '../resources/images/proenca_circled.png';
import luisImage from '../resources/images/la_circled.png';
import joelImage from '../resources/images/team/pedro_marques.png';
import brunobussImage from '../resources/images/team/bruno_buss.png';

import linkedInIcon from '../resources/icons/linkedin_icon_filled.png';
import websiteIcon from '../resources/icons/website_filled_white.png';
import { useTranslation } from 'react-i18next';

interface AboutTeamProps {
}

const TEAM = {
  description: "",
  image: "",
  background_image: "../resources/images/pattern_4.jpg",
  groups: [
    {
      name: "CORE TEAM",
      members: [
        {
          name: "Vasco Lopes",
          role: "CEO",
          description: "Bio...",
          image: vascoImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/vasco-lopes/"
        },
        {
          name: "Bruno Degardin",
          role: "CTO",
          description: "Bio...",
          image: brunodImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/bruno-degardin-2a2969158/"
        },
        {
          name: "Vítor Crespo",
          role: "CSO",
          description: "Bio...",
          image: vitorImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/vitorc/"
        },
        {
          name: "Bruno Cardoso",
          role: "CFO",
          description: "Bio...",
          image: brunocImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/bruno-cardoso-finance/"
        },
        {
          name: "Gabriela Martins",
          role: "Business Manager",
          description: "Bio...",
          image: gabrielaImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/gabrielacmartins/"
        },
        {
          name: "Vânia Fernandes",
          role: "Head of Marketing",
          description: "Bio...",
          image: vaniaImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/vvfernandes/"
        },
        {
          name: "Luiz Zanlorensi",
          role: "Data Scientist",
          description: "Bio...",
          image: luizImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/luiz-antônio-zanlorensi-04214749/"
        },
        {
          name: "Pedro Brito",
          role: "Software / ML Engineer",
          description: "Bio...",
          image: pedroImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/pedrobritoo/"
        },
        {
          name: "João Brito",
          role: "Software / ML Engineer",
          description: "Bio...",
          image: joaoImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/joão-brito-187820160/"
        },
        {
          name: "Bruno Buss",
          role: "Frontend Developer",
          description: "Bio...",
          image: brunobussImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/bruno-buss-20b637168/"
        },
        {
          name: "Pedro Marques",
          role: "Frontend Developer",
          description: "Bio...",
          image: joelImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/pedro-marques-535b34236/"
        }
      ]
    },
    {
      name: "ADVISORS",
      members: [
        {
          name: "Lars Wahlström",
          role: "Board Advisor",
          description: "Bio...",
          image: larsImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/larswahlstrom/"
        },
        {
          name: "João Neves",
          role: "Tech Advisor",
          description: "Bio...",
          image: joaoNImage,
          icon: linkedInIcon,
          link: "https://www.linkedin.com/in/joao-neves-6b692759/"
        },
        {
          name: "Hugo Proença",
          role: "Tech Advisor",
          description: "Bio...",
          image: hugoImage,
          icon: websiteIcon,
          link: "http://www.di.ubi.pt/~hugomcp/index.html"
        },
        {
          name: "Luís Alexandre",
          role: "Tech Advisor",
          description: "Bio...",
          image: luisImage,
          icon: websiteIcon,
          link: "http://www.di.ubi.pt/~lfbaa/"
        }
      ]
    }
  ]
}

const tiltOptions = {
  reverse: true,  // reverse the tilt direction
  max: 30,     // max tilt rotation (degrees)
  perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
  scale: 1.01,    // 2 = 200%, 1.5 = 150%, etc..
  speed: 1000,   // Speed of the enter/exit transition
  transition: true,   // Set a transition on enter/exit.
  axis: null,   // What axis should be disabled. Can be X or Y.
  reset: true,    // If the tilt effect has to be reset on exit.
  easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
  glare: true,  // if it should have a "glare" effect
  "max-glare": 1,      // the maximum "glare" opacity (1 = 100%, 0.5 = 50%)
  "glare-prerender": false,  // false = VanillaTilt creates the glare elements for you, otherwise
}


export function AboutTeam({ }: AboutTeamProps) {
  const { t, i18n } = useTranslation();

  // json data
  const json_data = TEAM
  // ------------------------------------
  // redirect the user to the chosen page
  // ------------------------------------
  const handle_click = (link: string) => {
    window.open(link, "_blank");
  }

  const handleOnMouseMove = (e: any) => {
    const cards = document.getElementsByClassName("card");

    for (let i = 0; i < cards.length; i++) {
      const card = cards[i] as HTMLElement;
      const rect = card.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      card.style.setProperty("--mouse-x", `${x}px`);
      card.style.setProperty("--mouse-y", `${y}px`);
    }
  }


  return (
    <>
      <section className="w-full mt-32 bg-gradient-to-b" >
        <div className="w-full mx-auto max-w-7xl p-10 dark:text-white text-neutral-600 " >
          <motion.div className="w-full flex items-center justify-center"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <div className="w-full h-[2px] bg-gradient-to-l from-neutral-500 to-background-900" ></div>
            <h3 className="font-light text-xl mx-2 w-96  text-center" >{t("meet_team")}</h3>
            <div className="w-full h-[2px] bg-gradient-to-r from-neutral-500 to-background-900" ></div>
          </motion.div>

          <motion.h2
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="text-lg sm:text-xl tracking-tight text-center">{t("meet_team_text")}
          </motion.h2>

          <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-y-8 lg:gap-x-12  items-center justify-center relative z-10">

          </div>

          {/* <div className={styles.team_div} id="team">
            <div className={styles.fade_div_bottom} style={{ bottom: "0", height: "75%", zIndex: "22", backgroundImage: "linear-gradient(to top, rgba(243,243,243,0.0), rgba(243,243,243,1))" }} />
            <div className={styles.team_div_background}  />
            <div className={styles.team_div_inner}> */}

          {json_data.groups.map((group) => (
            <div className={styles.team_group_div} key={json_data.groups.indexOf(group)}>
              <motion.h3
                initial={FADE_BOTTOM.initial}
                whileInView={FADE_BOTTOM.final}
                transition={{
                  duration: 0.4,
                  delay: 0.3
                }}
                viewport={{ once: true }}
                className="text-base sm:text-lg tracking-tight w-full text-center">{t(`${group.name}`)}
              </motion.h3>
              <div className={styles.team_cards_div}>
                <div id="cards" className={styles.team_cards_div_inner}>
                  {group.members.map((team_member) => (
                    <Tilt options={tiltOptions}>
                      <div onMouseMove={handleOnMouseMove} className={classNames(styles.team_card, "group relative  bg-transparent")} key={group.members.indexOf(team_member)}>
                        <div className="card rounded-lg cursor-pointer"  onClick={() => handle_click(team_member.link)} >
                          <div className="card-border" ></div>

                          <div className={
                            classNames(
                              "dark:bg-neutral-900 bg-white z-[2] rounded-lg"
                            )
                          }
                           >
                            {/* <div className="h-1" /> */}
                            <div className={classNames(styles.team_card_content, "py-1 cursor-pointer")}>
                              <div className="w-[100px] h-[100px] mx-auto rounded-full mt-[15px] border-black border overflow-hidden " >
                                <img src={team_member.image} alt="" />
                              </div>

                              <div className={styles.team_card_icon_div}>
                                <div className={styles.vertical_align} style={{ height: "fit-content", width: "fit-content", padding: "0" }}>
                                  <div className={styles.team_card_icon}>
                                    <img src={team_member.icon} alt="" />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.team_card_name_role_div}>
                                <div className={" text-center font-semibold text-sm"} dangerouslySetInnerHTML={{ __html: team_member.name }} />
                                <div className={"dark:text-neutral-400 text-gray-600 text-center text-sm h-[30px] mt-1"} dangerouslySetInnerHTML={{ __html: team_member.role }} />
                              </div>
                              {/*<div className = {styles.team_card_description} dangerouslySetInnerHTML = {{__html: team_member.description}} />*/}
                            </div>
                          </div>
                        </div>

                      </div>
                    </Tilt>
                  ))}
                </div>
              </div>
            </div>
          ))}

          {/* </div> */}
          {/* </div> */}

        </div>
      </section>
    </>
  );
}

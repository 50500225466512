import { ReactNode, useEffect, useState } from 'react';
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import { Button } from './Button';
import { INDUSTRIES, IndustriesType } from '../resources/constants';
//@ts-ignore
import testVideo from "../resources/videos/hero_video.mp4"
import fireDetection from "../resources/videos/industries/fireDetection.mp4"

import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { classNames } from '../utils';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IndustryVideoSectionProps {
  industry?: IndustriesType
}

export function IndustryVideoSection({ industry }: IndustryVideoSectionProps) {
  const [activeTab, setActiveTab] = useState<string | undefined>();
  const [render, setRender] = useState(false)
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (render)
      setRender(false)
  }, [industry, pathname])

  useEffect(() => {
    if (!render) {
      setRender(true)
      setActiveTab(industry?.examples[0].id)
    }
  }, [render])

  return (
    <div className="w-full lg:h-[700px] p-12 lg:py-24 dark:text-white text-neutral-600  relative md:border md:border-white md:rounded-3xl dark:bg-neutral-900 bg-white " >
      <div>
        {render && (
          <Tabs
            animate={{
              initial: { y: 200 },
              mount: { y: 0, transition: { duration: 0.100 } },
              unmount: { y: 200, transition: { duration: 0.100 } },

            }} id="custom-animation" value={industry?.examples[0].id}>
            <TabsHeader
              className="bg-transparent items-center flex"
              indicatorProps={{
                className: "bg-neutral-100 shadow-none text-blue-900 rounded-2xl",
              }}
            >
              {industry && industry.examples.map((item, index) => (
                <Tab key={index} value={item.id}
                  className={classNames(
                    activeTab === item.id ? "text-neutral-900 h-[48px] text-xs md:text-sm" : "h-[48px] text-xs md:text-sm",
                    "flex items-center justify-center delay-200"
                  )}
                  onClick={() => setActiveTab(item.id)}
                >
                  {t(`${item.name}`)}
                </Tab>
              ))}
            </TabsHeader>

            <TabsBody>
              {industry && industry.examples.map((item, index) => (
                <TabPanel key={index} value={item.id}>
                  <div className="lg:flex justify-between gap-10 w-full" >
                    <div className=" md:max-w-[700px] md:max-h-[400px] rounded-lg" >
                      {/* @ts-ignore */}
                      {item.video ? (
                        <video
                          className=" w-full rounded-lg object-contain"
                          // @ts-ignore
                          src={item.video}
                          autoPlay
                          muted
                          loop
                        />
                      ) : (
                        <img
                          className=" w-full rounded-lg object-contain"
                          // @ts-ignore
                          src={item.image ?? ""}
                        />
                      )}

                    </div>

                    <div className="md:flex-1 h-full min-w-[220px] flex flex-col justify-between" >
                      <div className="mt-12" >
                        <p className="text-lg font-semibold" >
                        {t(`${item.title}`)}
                          {/* Monitor traffic flow and detect potential safety concerns in real-time with our advanced computer vision technology. */}
                        </p>
                        <p className="mt-2 text-sm dark:text-gray-400 text-gray-600" >
                        {t(`${item.description}`)}
                          {/* Take action to alleviate traffic and improve safety, by monitoring: */}
                        </p>
                      </div>

                      <div className="mt-2 text-sm dark:text-gray-200 text-neutral-900" >
                        <ul>
                          {item.includes.map((i) => (
                            <li>
                             {t(`${i}`)}
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="w-full flex justify-center lg:justify-end mt-4 lg:mt-0" >

                        <Button>{t("cta")}</Button>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ))}
            </TabsBody>

          </Tabs>
        )}


      </div>
    </div>
  );
}

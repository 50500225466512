/**
 * Navbar component
 */
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from './Button';
//@ts-ignore
import Logo from "../resources/images/DEEPNEURONIC_logotipo_preto.png"
//@ts-ignore
import WhiteLogo from "../resources/images/DEEPNEURONIC_logotipo_branco.png"
import { DropdownOption, DropdownProvider, DropdownRoot } from './Menu';
import { DropdownStyles } from './Menu/styles';
import { Company, Solutions } from './MenuContent';
// Icons
import CompanyIcon from "../resources/icons/menu_icons/company.svg"
import { COMPANY_MENU, ICONS, IconType, SOLUTIONS_MENU } from '../resources/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import Switch from "react-switch";

import styles from '../styles/settings_popup.module.scss';
import { Cta } from './Cta';
import { DarkModeToggle } from './DarkModeToggle';
import { useDarkMode } from '../hooks/useTheme';

//----------------------------------------------------------------
interface NavbarProps {
  /**Component theme */
  theme?: "light" | "dark"
  /**Component language */
  language?: "en" | "pt"
  /**Toggle navbar transparency according to its position */
  transparent?: boolean
}
//----------------------------------------------------------------



//----------------------------------------------------------------
function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export function Navbar({ transparent = false }: NavbarProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [languageState, setLanguageState] = useState(false);
  const language = localStorage.getItem('language');
  const { theme, setTheme } = useDarkMode()

  const handleIndustrySelection = (link: string) => {
    history.push(`${link}`);
  }

  const navigateHome = () => {
    history.push("/");

  }

  const handleLanguageStateChange = (nextChecked: any) => {
    setLanguageState(nextChecked);
    change_language(nextChecked ? "en" : "pt");
  };

  const change_language = (selected_language: any) => {
    localStorage.setItem('language', selected_language);
    i18n.changeLanguage(selected_language);
    // window.location.reload();
  }

  useEffect(() => {
    setLanguageState(language === "pt" ? false : true);
    i18n.changeLanguage(language === "en" ? "en" : "pt");
    // eslint-disable-next-line
  }, []);

  return (
    <div className="fixed top-0 z-50 w-full" >

      <header
        className={classNames(
          "duration-300",
          transparent ? "bg-transparent text-gray-200" : 'bg-white dark:bg-neutral-900  text-gray-500 shadow-xl shadow-primary-600/20',
        )}
      >
        <DropdownProvider>
          <DropdownStyles>

            <Disclosure as="nav">
              {({ open }) => (
                <>
                  <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 items-center justify-between">
                      <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                        {/* Mobile menu button*/}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                            <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>

                      <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                        <div className="flex flex-shrink-0 items-center cursor-pointer">
                          <img
                            onClick={navigateHome}
                            className="block h-10 w-36"
                            src={ theme=== "dark" ? WhiteLogo : transparent ? WhiteLogo : Logo}
                            alt="logo" />
                        </div>

                        <div className="hidden lg:ml-6 lg:block">
                          <ul className="flex h-full items-center justify-center" >

                            <li>
                              <DropdownOption
                                name="Solutions"
                                content={Solutions}
                                offset={200}

                                backgroundHeight={0}
                              />
                            </li>

                            <li>
                              <DropdownOption
                                name="Company"
                                content={Company}
                                backgroundHeight={0}
                              />
                            </li>

                            <li>
                              <div className={styles.switch_div}>
                                <div className={styles.vertical_align}>
                                  <div className={styles.switch}>
                                    <Switch
                                      onChange={handleLanguageStateChange}
                                      checked={languageState}
                                      handleDiameter={22}
                                      className={styles.switch_style}
                                      height={25}
                                      width={55}
                                      offColor="#f3f3f3"
                                      onColor="#000000"
                                      offHandleColor="#000000"
                                      onHandleColor="#f3f3f3"
                                      checkedIcon={ // dark mode icon
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            paddingRight: 2
                                          }}
                                        >
                                          <div className={styles.switch_icon_text} style={{ color: "white" }}>EN</div>
                                        </div>
                                      }
                                      uncheckedIcon={ // dark mode icon
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                            paddingRight: 2
                                          }}
                                        >
                                          <div className={styles.switch_icon_text} style={{ color: "black" }}>PT</div>
                                        </div>
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </li>


                          </ul>

                        </div>

                      </div>



                      <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
                      <div
                          className={classNames(
                            "space-x-4 hidden lg:flex items-center",
                            languageState ? "w-full" : "w-[300px]"
                          )}
                        >
                          <div>
                          <DarkModeToggle />
                          </div>

                          <Cta className="w-full" size='small'>
                            {t("cta")}
                          </Cta>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Disclosure.Panel
                    className={classNames(
                      "lg:hidden ",
                      transparent ? "bg-background-900" : "bg-neutral-900"
                    )}>
                    <div className="space-y-1 px-2 pt-2 pb-3">
                      <div className="my-4 w-full flex items-center justify-center" >
                        <div className={styles.switch_div}>
                          <div className={styles.vertical_align}>
                            <div className={styles.switch}>
                              <Switch
                                onChange={handleLanguageStateChange}
                                checked={languageState}
                                handleDiameter={22}
                                className={styles.switch_style}
                                height={25}
                                width={55}
                                offColor="#f3f3f3"
                                onColor="#000000"
                                offHandleColor="#000000"
                                onHandleColor="#f3f3f3"
                                checkedIcon={ // dark mode icon
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      paddingRight: 2
                                    }}
                                  >
                                    <div className={styles.switch_icon_text} style={{ color: "white" }}>EN</div>
                                  </div>
                                }
                                uncheckedIcon={ // dark mode icon
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      height: "100%",
                                      paddingRight: 2
                                    }}
                                  >
                                    <div className={styles.switch_icon_text} style={{ color: "black" }}>PT</div>
                                  </div>
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="w-full" >
                          <DarkModeToggle />
                          </div>
                      </div>

                      <p className="text-gray-400 font-bold" >INDUSTRIES</p>


                      {SOLUTIONS_MENU.items.first_column.map((item) => (
                        <div key={item.name} onClick={() => handleIndustrySelection(item.link)} >
                          <Disclosure.Button


                            className={classNames(
                              'hover:underline hover:opacity-80 cursor-pointer',
                              'block px-3 py-1 rounded-md text-sm font-medium',
                              "flex items-center"
                            )}
                          >
                            <span className=" w-8 h-8 rounded-full bg-background-900 mr-1 p-1 flex items-center justify-center" >
                              <img className=" w-4 group-hover:scale-110 duration-200 " src={item.icon} alt={item.name} />
                            </span>
                            {t(`${item.name}`)}
                          </Disclosure.Button>
                        </div>
                      ))}
                    </div>

                    <div className="separator border-t border-neutral-600 w-[80%] mx-auto mt-1 mb-2" />

                    <div className="space-y-1 px-2 pt-2 pb-3 ">
                      <p className="text-gray-400 font-bold" >COMPANY</p>
                      {COMPANY_MENU.items.map((item) => (
                        <div key={item.name} onClick={() => handleIndustrySelection(item.link)} >
                          <Disclosure.Button
                            key={item.name}
                            className={classNames(
                              'hover:underline hover:opacity-80 cursor-pointer',
                              'block px-3 py-1 rounded-md text-sm font-medium',
                              "flex items-center"
                            )}
                          >
                            <span className=" w-8 h-8 rounded-full bg-background-900 mr-1 p-1 flex items-center justify-center" >
                              <img className=" w-4 group-hover:scale-110 duration-200 " src={ICONS[item.icon]} alt={item.name} />
                            </span>
                            {t(`${item.name}`)}
                          </Disclosure.Button>
                        </div>

                      ))}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <DropdownRoot />
          </DropdownStyles>

        </DropdownProvider>
      </header>
    </div>

  );
}

import React from 'react';
import { ComponentStory, ComponentMeta } from '@storybook/react';
import { Industry } from '../pages';

export default {
  title: 'Pages/Industry',
  component: Industry,
  parameters: {
    layout: 'fullscreen',
  },

} as ComponentMeta<typeof Industry>;

const Template: ComponentStory<typeof Industry> = (args) => <Industry />;


export const Default = Template.bind({});

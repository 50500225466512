import { motion } from 'framer-motion'

interface HomePatternProps {
}

export function HomePattern({ }: HomePatternProps) {
  return (
    <>
      <svg width="801" height="1410" viewBox="0 0 801 1410" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M703.207 652H48.4914C9.93249 652 0.796875 670.815 0.796875 694.027L0.796875 809.602" stroke="url(#wave-1)" strokeWidth={3} />
        <path d="M704 652H747.036C776.291 652 800.004 639.214 800.004 623.429V52" stroke="url(#wave-1)" strokeWidth={3}  />
        <path d="M1 1410L1 810" stroke="url(#wave-1)" strokeWidth={3}  />
        <path d="M800.002 52V1H722.767H279.062" stroke="url(#wave-1)" strokeWidth={3}  />
        <defs>
          <motion.linearGradient
            // animate={{
            //   x1: [0, 801 * 2],
            //   x2: [0, 801],
            //   y1: [1410, 1410 / 2],
            //   y2: [1410 * 2, 1410]
            // }}
            // transition={{
            //   duration: 5,
            //   repeat: Infinity,
            //   ease: 'linear'
            // }}
            id="wave-1"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#8257e6" stopOpacity="0" />
            <stop stopColor="#8257e6" />
            <stop offset="1" stopColor="#f142b6" />
          </motion.linearGradient>
          <linearGradient id="paint0_linear_512_45" x1="-513.274" y1="3260.3" x2="-513.274" y2="3257.67" gradientUnits="userSpaceOnUse">
            <stop />
            <stop offset="1" />
          </linearGradient>
          <linearGradient id="paint1_linear_512_45" x1="-719.507" y1="2425.21" x2="-719.507" y2="2423.43" gradientUnits="userSpaceOnUse">
            <stop />
            <stop offset="1" />
          </linearGradient>
          <linearGradient id="paint2_linear_512_45" x1="-717.101" y1="2299.66" x2="-717.101" y2="2298.06" gradientUnits="userSpaceOnUse">
            <stop />
            <stop offset="1" />
          </linearGradient>
        </defs>
      </svg>

    </>
  );
}

/**
 * Color palettes file that contains all colors used on the app.
 * @PRIMARY and @SECONDARY can be easily switched by any of the color/shades objects
 */

const BLUE = {
  50: "#E9F3FE",
  100: "#D2E6FE",
  200: "#A6CEFD",
  300: "#79B5FB",
  400: "#4D9DFA",
  500: "#2084F9",
  600: "#1A6AC7",
  700: "#134F95",
  800: "#0D3564",
  900: "#061A32"
}

const BRAND_BLUE = {
  50: "#C5E8F7",
  100: "#AFDBEF",
  200: "#83C2DE",
  300: "#58AACE",
  400: "#2C91BD",
  500: "#0078AD",
  600: "#00608A",
  700: "#004868",
  800: "#003045",
  900: "#001823"
}

const PURPLE = {
  50: "#F4F0F7",
  100: "#E8E1F0",
  200: "#D2C2E1",
  300: "#BBA4D1",
  400: "#A585C2",
  500: "#8E67B3",
  600: "#72528F",
  700: "#553E6B",
  800: "#392948",
  900: "#1C1524"
}

const PINK = {
  50: "#F9EEF4",
  100: "#F3DDE9",
  200: "#E8BBD3",
  300: "#DC9ABD",
  400: "#D178A7",
  500: "#C55691",
  600: "#9E4574",
  700: "#763457",
  800: "#4F223A",
  900: "#27111D"
}

export const NEUTRAL = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#4E5153',
  600: '#232629',
  700: '#151719',
  800: '#0E0F10',
  900: '#070808',
};

export const WARNING = {
  100: '#FFF8D1',
  200: '#FFE9A3',
  300: '#FFDE76',
  400: '#FFD348',
  500: '#FFC81A',
  600: "#CCA015",
  700: "#997810"
};
export const ERROR = {
  100: '#FFE7D9',
  200: '#F3B7B5',
  300: '#EE9390',
  400: '#E86F6B',
  500: '#E24B46',
  600: '#B72136',
  700: '#7A0C2E',
};

// primary and secondary color config
export const PRIMARY = {
  ...BRAND_BLUE
}

export const SECONDARY = {
  ...PINK
}


export type ColorType = typeof PRIMARY
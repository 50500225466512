import { ReactNode, useEffect, useState } from 'react';
import { ContactUsSection, CtaCard, SolutionsGrid, SolutionsHero, SolutionsMenu, Navbar, UseCaseHero, UseCaseGrid } from '../components';
import { useScroll } from 'framer-motion';
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import { USE_CASES, UseCaseType } from '../resources/constants';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../components/Footer';

interface UseCaseProps {
}

type RouteParams = {
  solutionId: string;
};

export function UseCase({ }: UseCaseProps) {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { solutionId } = useParams<RouteParams>();
  const [useCase, setUseCase] = useState<UseCaseType | undefined>()
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const object = USE_CASES.find(item => item.id === solutionId);
    setUseCase(object)
    
  }, [pathname]);



  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })

  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef0f3] min-h-screen w-full font-roboto overflow-hidden" >
      <Navbar transparent={navbarTransparent} />
      <UseCaseHero title={useCase?.title ?? ""} useCase={useCase} />

      <section className="mt-40 w-full mx-auto max-w-7xl " >
        {/* <div className="pt-28" >
          <motion.h3
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="text-xl sm:text-2xl md:text-3xl font-bold tracking-tight text-white">Computer Vision Solutions
          </motion.h3>

          <motion.p
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="mt-10 text-base sm:text-lg font-bold tracking-tight text-gray-200">
            {useCase?.description}
          </motion.p>

        </div> */}
      </section>
      <UseCaseGrid useCase={useCase} />

      <section className=" w-full mx-auto max-w-7xl " >
        <CtaCard />
      </section>
      <ContactUsSection />
      <Footer/>

    </div>
  );
}
import { ReactNode, useEffect, useState } from 'react';
import { ContactUsSection, CtaCard, IndustryVideoSection, Navbar, IndustryGrid } from '../components';
import { useScroll } from 'framer-motion';
import { IndustryHero } from '../components/IndustryHero';
import { useLocation, useParams } from "react-router-dom";
import { INDUSTRIES, IndustriesType } from '../resources/constants';
import Footer from '../components/Footer';

interface IndustriesProps {
  children: ReactNode;
}

type RouteParams = {
  industryId: string;
};

export function Industry() {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { industryId } = useParams<RouteParams>();
  const [industry, setIndustry] = useState<IndustriesType | undefined>()
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    const object = INDUSTRIES.find(item => item.id === industryId);
    setIndustry(object)

  }, [pathname]);



  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })

  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef0f3] min-h-screen w-full font-roboto overflow-hidden text-xs sm:text-sm md:text-base" >
      <Navbar transparent={navbarTransparent} />
      <IndustryHero title={`${industry?.name}`} industry={industry} />
      <IndustryGrid industry={industry} />
      <section className="w-full mx-auto max-w-7xl " >
        <CtaCard />
      </section>
      <ContactUsSection />
      <Footer/>

    </div>
  );
}
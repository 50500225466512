import { ReactNode, useEffect, useState } from 'react';
import { ContactUsSection, CtaCard, SolutionsGrid, SolutionsHero, SolutionsMenu, Navbar } from '../components';
import { useScroll } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';

interface IndustriesProps {
  children: ReactNode;
}

export function Solutions() {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })

  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef0f3] min-h-screen w-full font-roboto overflow-hidden" >
      <Navbar transparent={navbarTransparent} />
      <SolutionsHero />
      <SolutionsGrid />
      <SolutionsMenu />
      <section className="w-full mx-auto max-w-7xl " >
        <CtaCard />
      </section>
      <ContactUsSection />
      {/* <HomeGridSection/>
      <HomeCardsSection/>
      <HomeFeatureSection/> */}
      <Footer />

    </div>
  );
}
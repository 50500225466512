// import Home from "./Home";
import Navbar from "./Navbar";
import { Link } from 'react-scroll';
import styles from './styles/app.module.scss';
//@ts-ignore
import ScrollUpButton from "react-scroll-up-button";
import up_arrow_blue from "./resources/icons/up_arrow_dark_blue.png";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import './styles/globals.css'
import { About, ContactUs, Home as NewHome, Industry, PrivacyPolicy, Solutions, TermsConditions, UseCase } from "./pages";
import Home from "./Home";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DarkModeProvider } from "./hooks/useTheme";

const App = () => {


    //////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////
    let language = localStorage.getItem('language');

    // set the default values (if they have never been set before)
    if (language === null) {
        localStorage.setItem('language', "en");
        language = "en";
    }

    window.addEventListener('resize', () => {
        //@ts-ignore
        document.querySelector(':root').style
            .setProperty('--vh', window.innerHeight / 100 + 'px');
    })


    return (
        <Router>
            <DarkModeProvider>
                <div className={styles.app}>
                    <div className={styles.app_content_desktop}>
                        <Switch>
                            <Route exact path="/legacy/v_1">
                                <div className={styles.app_navbar_desktop}>
                                    <Navbar language={language} />
                                </div>
                                <Link activeClass="active" to="home" spy={true} smooth={true} duration={100}>
                                    <ParallaxProvider>
                                        <Home language={language} />
                                    </ParallaxProvider>
                                </Link>
                            </Route>

                            <Route exact path="/">
                                <NewHome />
                            </Route>

                            <Route path="/industries/:industryId">
                                <Industry />
                            </Route>

                            <Route exact path="/solutions">
                                <Solutions />
                            </Route>

                            <Route path="/solutions/:solutionId">
                                <UseCase />
                            </Route>

                            <Route path="/contact">
                                <ContactUs />
                            </Route>

                            <Route path="/about">
                                <About />
                            </Route>

                            <Route path="/privacy_policy">
                                <PrivacyPolicy />
                            </Route>
                            <Route path="/terms">
                                <TermsConditions />
                            </Route>
                        </Switch>

                        {/* button that redirects the user back to the top of the page, if needed */}
                        <div className="relative z-50" >
                            <ScrollUpButton ContainerClassName={styles.button_container} TransitionClassName={styles.button_transition}>
                                <div className={styles.button_container}>
                                    <div className={styles.vertical_align}>
                                        <div>
                                            <img className={styles.up_arrow} src={up_arrow_blue} alt="Up arrow" />
                                        </div>
                                    </div>
                                </div>
                            </ScrollUpButton>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </DarkModeProvider>
        </Router>
    );

}

export default App;
import Balancer from 'react-wrap-balancer'
import { Cta } from './Cta';
// @ts-ignore
import Vision from "../resources/videos/home/bg2.mp4"
import HealthTech from "../resources/images/healthtech.png"
import Ubi from "../resources/images/ubi.png"
import UbiMedical from "../resources/images/ubimedical.png"
import HeroImg from "../resources/images/hero.png"
import { Button } from './Button';
import { classNames } from '../utils';
import { useTranslation } from 'react-i18next';

export default function AboutHeroSection() {
  const { t, i18n } = useTranslation();

  return (
    <div className="isolate dark:bg-transparent bg-primary-500 h-[calc(100vh)] md:h-[800px]   overflow-hidden flex md:items-center relative w-full">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
        <svg
          className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#42a5f5" />
              <stop offset={1} stopColor="#3849ab" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      {/* Overlay */}
      <div className="h-[calc(100vh)] md:h-[900px]    w-screen absolute dark:opacity-75 opacity-90  bg-gradient-to-r from-transparent to-primary-500" >

        <video className="w-full h-[calc(100vh)] md:h-[900px]  object-cover" src={Vision} autoPlay loop muted />
        <div className="h-[calc(100vh)] md:h-[900px]    w-screen absolute  z-10 bottom-0 opacity-20 dark:opacity-30 bg-black">
        </div>
        <div className="h-[calc(100vh)] md:h-[900px]  opacity-20 dark:opacity-50  w-screen absolute  bg-gradient-to-r z-10 bottom-0 from-black via-black/50  to-black">

        </div>
      </div>

      <main className="w-full flex justify-center mt-32 md:mt-0" >
        <div className="relative px-6 lg:px-0 max-w-7xl ">
          <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">
            <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-1 sm:gap-y-16 lg:gap-x-8 items-center " >

              <div className="text-center md:text-center p-4" >
                <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold text-white">

                  <Balancer>
                    {/* Detect threatening and abnormal activities in real-time */}
                    {t("Our mission is to use")}
                    <span
                      className=" bg-gradient-to-r from-cyan-400 to-primary-500  text-transparent bg-clip-text bg-300% animate-gradient"
                    >
                      {" "}{t("AI")}{" "}
                    </span>
                    {t("and human expertise for the greater good")}
                    {/* <span
                      className=" bg-gradient-to-r from-secondary-500 via-primary-500 to-secondary-400 text-transparent bg-clip-text bg-300% animate-gradient"
                    >
                      {" "}Intelligence{" "}
                    </span> */}
                    


                  </Balancer>
                </h1>
                <div className="w-full flex mt-4 justify-center md:justify-center" >
            {/* <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-gray-500">
            Using AI and human expertise for the greater good
            </h2> */}
                </div>

              </div>

              {/* <img className="min-w-[900px] " src={HeroImg} alt="hero" /> */}


              {/* Pattern */}
              <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3849ab" />
                      <stop offset={1} stopColor="#42a5f5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

            </div>
          </div>
        </div>
      </main>

      {/* <div className="absolute bottom-5 md:px-20 w-full h-16 flex items-center justify-between opacity-75" >
        <img className="h-8 sm:h-10 md:h-16" src={UbiMedical} alt="HealthTech" />
        <img className="h-8 sm:h-10 md:h-16" src={HealthTech} alt="HealthTech" />
        <img className="h-8 sm:h-10 md:h-16" src={Ubi} alt="HealthTech" />
      </div> */}
    </div>
  )
}

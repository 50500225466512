import { useHistory } from 'react-router-dom';
import styles from '../styles/button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}

export function Cta({ label, primary, size = 'medium', ...props }: ButtonProps) {
  const mode = primary ? 'button_primary' : 'button_secondary';
  const sizeStyle = size === "small" ? "py-1 lg:py-2" : "py-2 lg:py-3"
  const history = useHistory()

  const click = () => {
    history.push(`/contact`);
  }

  return (
    <>
      <button
        onClick={click}
        {...props}
        className={[`min-w-[140px] bg-white px-7 lg:px-8 rounded-lg font-bold ${props.className} btn-grad ${sizeStyle}`,].join(' ')}
      >
        <span>
          {label ?? props.children}
        </span>
      </button>
    </>
  );
}

import { FrameType } from "../types/PredictionType";
import { doPolygonAndRectIntersect, reduceRectSize } from "../utils/regions";

interface SvgPolygonProps {
    width: number;
    height: number;
    originalWidth: number
    originalHeight: number
    delimitedArea: any
    currentFrame: FrameType | null
    displayedParkingAreas: any
    onClick: (event: any) => any
}

export function SvgPolygon({ onClick, width, height, originalWidth, originalHeight, delimitedArea, currentFrame, displayedParkingAreas }: SvgPolygonProps) {

    const points = delimitedArea.POINTS ?? []

    // Normalize points based on original dimensions
    const normalizedPoints = points.map((point: any) => [
        point[0] / originalWidth,
        point[1] / originalHeight
    ]);

    // Scale normalized points based on new dimensions
    const scaledPoints = normalizedPoints.map((point: any) => [
        point[0] * width,
        point[1] * height
    ]);


    // const isIntersecting = (object: ObjectType): boolean => {
    //     return doPolygonAndRectIntersect(delimitedArea.POINTS, object.boundingBox);
    // };

    const getAreaColor = (): string => {
        if (!currentFrame) {
            const [r, g, b] = delimitedArea["COLOUR"];
            return `rgba(${r}, ${g}, ${b}, 0.3)`;
        } else {
            for (let obj of currentFrame.objects) {
                const reducedBoundingBox = reduceRectSize(obj.boundingBox, 0.8);
                if (doPolygonAndRectIntersect(delimitedArea.POINTS, reducedBoundingBox)) {
                    return `rgba(254,83,90,  ${displayedParkingAreas.occupied ? 0.3 : 0})`;
                }
            }

            const [r, g, b] = delimitedArea["COLOUR"];
            return `rgba(${r}, ${g}, ${b}, ${displayedParkingAreas.free ? 0.3 : 0})`;
        }
    };


    const pointsString = scaledPoints.map((point: any) => point.join(',')).join(' ');
    const fillColor = getAreaColor();

    return (
        <svg onClick={onClick} className="z-20 absolute top-0" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
            <polygon points={pointsString} fill={fillColor} stroke={fillColor} />
        </svg>
    );
}
import { useHistory } from "react-router-dom";
import { COMPANY_MENU, ICONS } from "../../resources/constants";
import { useTranslation } from "react-i18next";
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { scroller } from 'react-scroll';

export const Company = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleNavigate = (link: string) => {

    if (link === "/#partners") {
      // window.location.href = "#partners";
      scroller.scrollTo('partners', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }

    history.push(`${link}`);
  }

  return (
    <div className="p-12 whitespace-nowrap font-bold dark:bg-neutral-900 bg-white dark:text-white text-neutral-600" >
      <ul className="" >
        {COMPANY_MENU.items.map((item) => (
          <li onClick={() => handleNavigate(item.link)} className="text-base cursor-pointer flex items-center mt-4 first:mt-0  hover:text-primary-300 group" >
            <span className=" w-10 h-10 rounded-full dark:bg-neutral-800 bg-neutral-200  mr-2 p-1 flex items-center justify-center" >
              <img className=" w-6 group-hover:scale-110 duration-200" src={ICONS[item.icon]} alt={item.name} />
            </span>
            {t(`${item.name}`)}
          </li>
        ))}
        {/* <AnchorLink href='#partners'>Things</AnchorLink> */}
        {/* <AnchorLink href='#partners' className="text-base cursor-pointer flex items-center mt-4 first:mt-0  hover:text-primary-300 group" >
            <span className=" w-10 h-10 rounded-full dark:bg-neutral-800 bg-neutral-200  mr-2 p-1 flex items-center justify-center" >
            </span>
            {t(`Partners`)}
          </AnchorLink> */}
      </ul>
    </div>
  )
};
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';
import { ThemeProvider } from "@material-tailwind/react";

// React 18 render method
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);


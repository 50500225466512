import React from 'react';
import styles from './styles/socials.module.scss';
import json_socials_en from './jsons/en/socials_en.json';
import json_socials_pt from './jsons/pt/socials_pt.json';

const Socials = (props) => {

    ////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    ////////////////////////////////////////////////////////////////////////////////
    // json data
    const json_data = (props.language === "en" ? json_socials_en : json_socials_pt);

    const handle_click = (link) => {

        window.open(link, "_blank");
    }

    return (
        <div className = {styles.items_div}>
            {json_data.desktop.items.map((item) => (
                <div className = {styles.item_div} onClick = {() => handle_click(item.link)} key = {json_data.desktop.items.indexOf(item)}>
                    <div className = {styles.vertical_align} style = {{height: "100%"}}>
                        <div 
                            className = {styles.item_div_icon} 
                            style = {{backgroundImage: `url(${require(`${item.icon}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Socials;
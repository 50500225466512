/**
 * Component that draws bounding boxes around detected objects
 * on each video frames. It also displays labels and additional information about
 * the objects when users interact with the bounding boxes.
 *
 * The @filter prop allows to filter the displayed objects based on their
 * class, and the boxes will only be shown for objects that match the filter.
 */

import { useEffect, useState, Fragment } from 'react';
import { motion } from "framer-motion";
import { classNames } from '../utils';
import { classToColorMap, getColorStyles, isSmallPersonalObject } from '../utils/classes';
import { FrameType } from '../types/PredictionType';
import { doPolygonAndRectIntersect, reduceRectSize } from '../utils/regions';
// import { usePredictionsMenu } from '../hooks/usePredictionsMenu';

interface VideoDrawerProps {
  frames: any
  originalImageSize: ImageSizeObject
  currentFrame: FrameType
  isPlaying?: boolean
  selectedObject: string | null
  filter: string[]
  delimitedAreas?: any[]
}

type ImageSizeObject = {
  width: number
  height: number
}


const animationDurationRef = 0.1;

export function VideoDrawer({
  currentFrame,
  originalImageSize,
  isPlaying,
  selectedObject,
  filter,
  delimitedAreas
}: VideoDrawerProps) {
  const [imageSize, setImageSize] = useState<ImageSizeObject | null>(null)
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(selectedObject)
  const [expandedTooltip, setExpandedTooltip] = useState<string | null>(selectedObject)


  useEffect(() => {
    setExpandedTooltip(selectedObject)
    setVisibleTooltip(selectedObject)
  }, [selectedObject])

  const handleClick = (id: string | null) => {
    if (isPlaying) {
      setExpandedTooltip(null)
      return
    }
    if (id === expandedTooltip) {
      setExpandedTooltip(null)
    } else {
      setExpandedTooltip(id)
    }
  }

  const getLabelStyles = (className: string) => {
    switch (className) {
      case "smoke":
        return "bg-[#e4a872]/70 border-y border-[#e4a872] "

      case "fire":
        return "bg-[#E60000]/70 border-y border-[#E60000] "

      case "truck":
        return "bg-green-700/70 border-y border-green-500"

      default:
        return "bg-blue-700/70 border-y border-blue-500"
    }
  }

  const doAreasIntersect = (boundingBox:any) => {
    const reducedBoundingBox = reduceRectSize(boundingBox, 0.4);
    let intersect = false
    delimitedAreas?.map((area) => {
      if(doPolygonAndRectIntersect(area.POINTS, reducedBoundingBox)){
        intersect = true
      }
    })
    return intersect
  }

  const getSize = (size: number) => {
    if (!imageSize) {
      const _sizes = handleGetVideoSize()
      return _sizes.width * size / originalImageSize.width
    }
    return imageSize.width * size / originalImageSize.width
  }

  // gets image size and updates 
  const handleGetVideoSize = () => {
    const video = document.getElementById('video') as any
    if (video) {
      const { offsetWidth, offsetHeight } = video;
      setImageSize({ width: offsetWidth, height: offsetHeight })
      return { width: offsetWidth, height: offsetHeight }
    } else {
      // if image is not rendered yet returns original measurements
      return { ...originalImageSize }
    }
  }

  // Event listener for resizing the image
  useEffect(() => {
    window.addEventListener('resize', handleGetVideoSize);
    // remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleGetVideoSize);
    };
  }, []);

  // Event listener for resizing the image when entering full screen
  useEffect(() => {
    const video = document.getElementById('video');
    if (!video) return;

    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        if (entry.target === video) {
          handleGetVideoSize();
        }
      });
    });

    resizeObserver.observe(video);

    return () => {
      resizeObserver.unobserve(video);
    };
  }, [handleGetVideoSize]);

  return (
    <>
      {currentFrame && currentFrame.objects.map((object, index) => (
        <Fragment key={`${object.id}_${index}`}>
          {/* Bounding Box */}
          <div
            onClick={() => handleClick(object.id)}
            onMouseLeave={() => {
              setVisibleTooltip(null)
              handleClick(null)
            }}
            onMouseOver={() => setVisibleTooltip(object.id)}
            className={
              classNames(
                `border-[1px]  group cursor-pointer scale-100  z-20 hover:z-30  ${getColorStyles(object.classes[0].class, filter)}`,
                visibleTooltip === object.id ? "z-30" : "z-20",
                "rounded-[2px]",
                object.classes[0].class === "fire" && "z-30",
                !isPlaying && "hover:scale-110",
                (object.classes[0].confidence) < 0.32 ? "hidden" : "block",
                doAreasIntersect(object.boundingBox) && getColorStyles("occupied", filter, true)
              )}
            style={{
              position: "absolute",
              left: getSize(object.boundingBox[0]),
              top: getSize(object.boundingBox[1]),
              width: getSize(object.boundingBox[2]),
              height: getSize(object.boundingBox[3]),
            }}
          >
            <div
              onClick={() => handleClick(object.id)}
              onMouseLeave={() => {
                setVisibleTooltip(null)
                handleClick(null)
              }}
              onMouseOver={() => setVisibleTooltip(object.id)}
              className={
                classNames(
                  `border-[2.4px] visible group-hover:invisible cursor-pointer scale-100 hover:scale-105 z-20 hover:z-30 transition-transform duration-300`,
                  isSmallPersonalObject(object.classes[0].class) ? "rounded bg-white opacity-20 z-40" : "invisible"
                )}
              style={{
                position: "absolute",
                inset: 0,
              }}
            />
            {/* Pulsing dot */}
            <div
              className={
                classNames(
                  `animation-pulse w-2 h-2 rounded-full bg-neutral-800`,
                  object.id === visibleTooltip ? "invisible" : "visible",
                  isPlaying ? "invisible" : "visible"
                )}
              style={{
                position: "absolute",
                left: (getSize(object.boundingBox[2]) / 2) - 6,
                top: (getSize(object.boundingBox[3]) / 2) - 8,
              }}
            />
            {/* expandable name tag */}
            <motion.div
              onMouseLeave={() => setVisibleTooltip(null)}
              onMouseOver={() => setVisibleTooltip(object.id)}
              className={
                classNames(
                  `transition-all duration-300 overflow-hidden`,
                  visibleTooltip === object.id ? "opacity-100 z-[60]" : "opacity-0",
                )}
              style={{
                position: "absolute",
                left: 0,
                bottom: getSize(object.boundingBox[3]),
              }}
              animate={{
                height: expandedTooltip === object.id ? 60 : 32,
                width: expandedTooltip === object.id ? 140 : 80
              }}
              transition={{
                ease: "easeOut",
                width: { duration: animationDurationRef },
                height: { duration: animationDurationRef },
                pointerEvents: { delay: 0.05 },
              }}
            >
              {expandedTooltip === object.id ? (
                <div className={classNames(
                  "flex items-center flex-col w-full  border-y  text-white  justify-center text-xs p-1 rounded z-50",
                  getLabelStyles(object.classes[0].class)
                )}>
                  <p>
                    Object ID: {object.id}
                  </p>
                  <p>
                    Class: {object.classes[0].class}
                  </p>
                  <p>
                    Confidence: <span className={classNames(
                      Number(object.classes[0].confidence) >= 0.5 ? "text-green-300" : "text-green-500"
                    )} >{object.classes[0].confidence}</span>
                  </p>
                </div>
              ) : (
                <div className="capitalize items-center flex flex-col w-full text-white  justify-center text-xs p-1 rounded overflow-hidden">
                  <div
                    className={
                      classNames(
                        "capitalize mt-[2px] items-center flex flex-col w-full text-white  justify-center text-xs p-1  ",
                        visibleTooltip === object.id ? getLabelStyles(object.classes[0].class): "",
                      )}>
                    <p>
                      {object.classes[0].class}
                    </p>
                  </div>
                </div>

              )}
            </motion.div>
          </div>
        </Fragment>
      ))}
    </>
  );


}

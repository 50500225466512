import { useHistory } from 'react-router-dom';
import styles from '../styles/button.module.scss';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Is this the principal call to action on the page?
   */
  primary?: boolean;
  /**
   * What background color to use
   */
  backgroundColor?: string;
  /**
   * How large should the button be?
   */
  size?: 'small' | 'medium' | 'large';
  /**
   * Button contents
   */
  label?: string;
}

export function Button({ label, primary, size = 'small', ...props }: ButtonProps) {
  const mode = primary ? 'button_primary' : 'button_secondary';
  const sizeStyle = `button_${size}`
  const history = useHistory()

  const click = () => {
    history.push(`/contact`);
  }

  return (
    <>
      <button
        onClick={click}
        {...props}
        className={[,  `py-2 min-w-[100px] dark:bg-white px-6 lg:px-7 rounded-lg font-bold text-sm dark:text-black text-white bg-black ${props.className}`].join(' ')}

      >
        {label ?? props.children}
      </button>
    </>
  );
}

import { ReactNode } from 'react';
import { motion } from 'framer-motion'
import { INDUSTRIES } from '../resources/constants';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SolutionsGridProps {
}


const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}

export function SolutionsGrid({ }: SolutionsGridProps) {
  const history = useHistory()
  const { t, i18n } = useTranslation();

  const selectIndustry = (id: string) => {
    console.log(id)
    history.push(`/industries/${id}`)
  }

  return (
    <section className="w-full" >
      <div className="w-full mx-auto max-w-7xl p-10 dark:text-white text-neutral-600 relative" >
        <div className="absolute inset-x-0 top-[10rem] z-10 transform-gpu overflow-hidden blur-3xl sm:top-[0rem]">
          <svg
            className="relative left-[0px] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[20rem] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#42a5f5" />
                <stop offset={1} stopColor="#3849ab" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <motion.h2
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
          className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight  text-center mt-20">{t("Industry Solutions")}
        </motion.h2>

        <motion.h3
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
          className="mt-2 text-lg sm:text-xl font-bold tracking-tight dark:text-gray-400 text-gray-600 text-center">{t("solutions_slogan")}
        </motion.h3>

        <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  sm:gap-y-4 lg:gap-x-4  justify-center relative z-10">
          {INDUSTRIES.slice(0, 6).map((i) => (
            <div onClick={() => selectIndustry(i.id)} className="flex flex-col group items-center  p-6 lg:p-6 dark:hover:bg-black/10 hover:bg-white cursor-pointer duration-200 rounded-2xl ">
              <div className="w-[200px] min-h-[200px] overflow-hidden  rounded-2xl " >
                <img className="rounded-2xl group-hover:scale-125 duration-200" src={i.cover} alt="" />
              </div>
              <div className="w-full text-center mt-4" >
                <p className="text-xl" >
                  {t(`${i.name}`)}
                </p>
                <p className="mt-2" >
                  {t(`${i.title}`)}
                </p>
              </div>
            </div>
          ))}
        </div>


      </div>
    </section>
  );
}

import { ReactNode, useEffect, useState } from 'react';
import { Button, ContactUsSection, HomeCardsSection, HomeExamplesSection, HomeFeatureSection, HomeGridSection, HomeGridSection2, HomePattern, Navbar, Partners } from '../components';
import { Link as ScrollLink, animateScroll as scroll, scroller } from 'react-scroll';
import HomeHero from '../components/HomeHero';
import feature1 from "../resources/images/home/feature_1.png"
import feature2 from "../resources/images/home/feature_2.png"
import feature3 from "../resources/images/home/feature_3.png"
import { useScroll } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';

interface HomeProps {
}

export function Home({ }: HomeProps) {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      }else{
        setNavbarTransparent(true)
      }
    })

  }, [])

    useEffect(() => {
        if (window.location.hash === "#partners") {
            // scroll.scrollToBottom();  // or you can use scroll.scrollTo() if you want a specific position
            scroller.scrollTo('partners', {
              duration: 800,
              delay: 0,
              smooth: 'easeInOutQuart'
          });
        }
    }, [pathname]);
  
  return (
    <div className="dark:bg-background-900 bg-[#eef3fc] min-h-screen w-full font-roboto overflow-hidden text-xs sm:text-sm md:text-base relative" >
      
      <Navbar transparent={navbarTransparent} />
      <HomeHero />
      <HomeFeatureSection/>
      <HomeGridSection2/>
      <HomeCardsSection/>
      <HomeExamplesSection/>
      <Partners/>
      <ContactUsSection/>
      <Footer/>

    </div>
  );
}

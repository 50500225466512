import { ReactNode, useEffect, useState } from 'react';
import { AboutTeam, AwardsSection, Navbar } from '../components';
import Footer from '../components/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import AboutHeroSection from '../components/AboutHeroSection';
import AboutIcon from "../resources/icons/about_us.png"
import VisionIcon from "../resources/icons/vision.png"
import MissionIcon from "../resources/icons/mission.png"
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import { useTranslation } from 'react-i18next';

interface TermsConditionsProps {
}

export function TermsConditions({ }: TermsConditionsProps) {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleNavigate = () => {
    history.push(`/privacy_policy`);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })
  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef3fc] dark:text-white text-neutral-600 min-h-screen w-full font-roboto overflow-hidden" >
      <Navbar transparent={navbarTransparent} />

      <section className="isolate  dark:bg-transparent bg-primary-500 h-[400px] overflow-hidden flex md:items-center relative w-full">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#42a5f5" />
                <stop offset={1} stopColor="#3849ab" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* Overlay */}
        <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute opacity-75 bg-gradient-to-r from-transparent to-primary-500" >

          <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute  z-10 bottom-0 opacity-50 bg-black">
          </div>
          <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute  bg-gradient-to-r z-10 bottom-0 from-black via-black/50  to-black">

          </div>
        </div>

        <main className="w-full flex justify-center mt-32 md:mt-0" >
          <div className="relative px-6 lg:px-0 max-w-7xl ">
            <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-1 sm:gap-y-16 lg:gap-x-8 items-center " >

                <div className="text-center md:text-center p-4" >
                  <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold text-white">
                    DeepNeuronic {t("Terms and conditions")}
                  </h1>
                </div>

                {/* Pattern */}
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                  <svg
                    className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                    viewBox="0 0 1155 678"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                      fillOpacity=".3"
                      d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                    />
                    <defs>
                      <linearGradient
                        id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                        x1="1155.49"
                        x2="-78.208"
                        y1=".177"
                        y2="474.645"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3849ab" />
                        <stop offset={1} stopColor="#42a5f5" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

              </div>
            </div>
          </div>
        </main>
      </section>

      <div className="flex items-center justify-center w-full gap-8" >
        <div onClick={handleNavigate} className="w-32 dark:text-white text-center cursor-pointer " >
          {t("Privacy Policy")}
        </div>

        <div onClick={handleNavigate} className="w-32 text-primary-600 text-center cursor-pointer" >
          {t("Terms and conditions")}
        </div>
      </div>

      <section className="w-full dark:bg-neutral-900 bg-white  p-20" >
        <div className="w-full mx-auto max-w-7xl" >
          <h2 className="text-2xl md:text-3xl" >{t("Terms and conditions")}</h2>
          <p>Last updated: [July 12, 2023]</p>
          {/* About */}
          <motion.div className="flex gap-6 items-center justify-between mt-4"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full ">
              <p>
                {t("terms_p1")}
                {/* This Agreement is legally binding between you (“User”, “you” or “your”) and Neurónio Inédito (doing business as DeepNeuronic, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Website and Services. */}
                <p className="mt-2" >
                  <strong className="text-lg" >{t("terms_p2_highlighted")} </strong>{t("terms_p2")}
                </p>
              </p>

            </motion.div>

          </motion.div>

          {/* vision */}
          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full"

            >
              <h3 className="text-lg md:text-2xl">
                {t("terms_t1")}
              </h3>
              <p className="mt-2" >
                {t("terms_p3")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t2")}
              </h3>

              <p className="mt-2" >
                {t("terms_p4")}
              </p>

            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t3")}
              </h3>

              <p className="mt-2" >
                {t("terms_i")}
              </p>
              <ul>
                <li>(a) {t("terms_i1")}</li>
                <li>(b) {t("terms_i2")}</li>
                <li>(c) {t("terms_i3")}</li>
                <li>(d) {t("terms_i4")}</li>
                <li>
                  (e) {t("terms_i5")}
                </li>
                <li>(f) {t("terms_i6")}</li>
                <li> (g) {t("terms_i7")}</li>
                <li>(h) {t("terms_i8")}</li>
                <li>(i) {t("terms_i9")}</li>
                <li>(j) {t("terms_i10")}</li>
              </ul>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t4")}
              </h3>

              <p className="mt-2" >
                {t("terms_p5")}

              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t5")}
              </h3>

              <p className="mt-2" >
                {t("terms_p6")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t6")}
              </h3>

              <p className="mt-2" >
                {t("terms_p7")}
              </p>
            </motion.div>



          </motion.div>


          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
                {t("terms_t7")}
              </h3>

              <p className="mt-2" >
                {t("terms_p8")}
              </p>
              <p>
                https://www.deepneuronic.com/contact/
              </p>
              <p>
                UBIMEDICAL, Estrada Municipal 506, 6200-284 Covilhã, Portugal
              </p>
            </motion.div>

          </motion.div>
        </div>

      </section>


      <Footer />

    </div>
  );
}

import React from 'react';
import BreadCrumbs from './BreadCrumbs';
import SettingsPopup from "./SettingsPopup";
import { useHistory } from "react-router-dom";
import styles from './styles/footer.module.scss';
import json_footer_en from './jsons/en/footer_en.json';
import json_footer_pt from './jsons/pt/footer_pt.json';
import Socials from './Socials';

const Footer = (props) => {

    //////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////
    // json data
    const json_data = (props.language === "en" ? json_footer_en : json_footer_pt);

    let history = useHistory();
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    ////////////////////////////////////////////
    // AUXILIARY FUNCTIONS
    ////////////////////////////////////////////
    // ------------------------------------
    // redirect the user to the chosen page
    // ------------------------------------
    const handle_click = (link) => {

        if(link === "home"){
            history.push("/");
            window.scrollTo(0, 0);
            forceUpdate();
        }
    }

    const open_pdf = () => {
        window.open(require(`${"./resources/pdfs/Neuronio_Inedito_ficha_projeto.pdf"}`), "__blank");
    }

    // -----------------------------------------
    // open the site's legal information
    // -----------------------------------------
    const additional_legal_text_click = () => {
        
        // TODO: add a "terms & conditions" page
        
        return;
    }

    return ( 
        <div className = {styles.desktop_root_upper}>

            <div className = {styles.logo_div}>
                <div 
                    onClick = {() => open_pdf()}
                    className = {styles.logo} 
                    style = {{backgroundImage: `url(${require(`${json_data.desktop.bottom.feder_image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}
                />
            </div>

            <div className = {styles.desktop_root}>
                <div className = {styles.vertical_align_super}>
                    <div className = {styles.desktop_root_inner}>
                        <div className = {styles.desktop_root_main_inner}>
                        
                            <div className = {styles.left_block}>
                                <div className = {styles.vertical_align} style = {{height: "100%", width: "100%"}}>
                                    <div className = {styles.left_block_inner}>
                                        <div className = {styles.top}>
                                            {/*<div className = {styles.logo_div}>
                                                <div 
                                                    onClick = {() => handle_click("home")}
                                                    className = {styles.logo} 
                                                    style = {{backgroundImage: `url(${require(`${json_data.desktop.left.alternative_logo}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}
                                                />
                                            </div>*/}
                                            <span className = {styles.main_title} onClick = {() => handle_click("home")} dangerouslySetInnerHTML={{__html: json_data.desktop.left.title}}/>
                                            {/*<span className = {styles.version} dangerouslySetInnerHTML={{__html: json_data.desktop.left.version}} />*/}
                                        </div>
                                        <div className = {styles.bottom}>
                                            <div className = {styles.settings_div}>
                                                <SettingsPopup border_colour = {props.theme === "light" ? "var(--dark_blue)" : "rgb(84, 84, 84)"} theme = {props.theme} language = {props.language}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className = {styles.middle_block}>
                                <div className = {styles.block_title}>{json_data.desktop.middle.title}</div>
                                <Socials language = {props.language}/>
                            </div>
                            
                            <div className = {styles.right_div}>
                                <div className = {styles.vertical_align_right}>
                                    <div className = {styles.inner_div}>
                                        <div className = {styles.block_title}>{json_data.desktop.right.title}</div>
                                        {json_data.desktop.right.description.map((description_item) => (
                                            <div className = {styles.contacts_line} key = {json_data.desktop.right.description.indexOf(description_item)}>
                                                <div className = {styles.contacts_title}>{description_item.title}</div>
                                                <div className = {styles.contacts_separator}>{description_item.separator}</div>
                                                <div className = {styles.contacts_text} dangerouslySetInnerHTML = {{__html: description_item.text}} />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div className = {styles.bottom_div}>
                            <div className = {styles.horizontal_line}/>
                            <div className = {styles.legal_text_div}>
                                <div className = {styles.bread_crumbs_div}>
                                    <BreadCrumbs bread_crumbs_generic_message = {json_data.desktop.bottom.bread_crumbs_generic_message}/>
                                </div>
                                <div className = {styles.main_legal_text} dangerouslySetInnerHTML={{__html: json_data.desktop.bottom.copyright_text.replace("YEAR", new Date().getFullYear().toString())}} />
                                <div onClick = {() => additional_legal_text_click()} className = {styles.additional_legal_tex_div}>
                                    <div className = {styles.additional_legal_text} dangerouslySetInnerHTML={{__html: json_data.desktop.bottom.additional_info}}></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
import { ReactNode, useEffect, useState } from 'react';
import { AboutTeam, AwardsSection, Navbar } from '../components';
import Footer from '../components/Footer';
import { useHistory, useLocation } from 'react-router-dom';
import { useScroll } from 'framer-motion';
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import { useTranslation } from 'react-i18next';

interface PrivacyPolicyProps {
}

export function PrivacyPolicy({ }: PrivacyPolicyProps) {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();
  const history = useHistory();

  const handleNavigate = () => {
    history.push(`/terms`);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })
  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef3fc] dark:text-white text-neutral-600 min-h-screen w-full font-roboto overflow-hidden" >
      <Navbar transparent={navbarTransparent} />

      <section className="isolate dark:bg-transparent bg-primary-500 h-[400px] overflow-hidden flex md:items-center relative w-full">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#42a5f5" />
                <stop offset={1} stopColor="#3849ab" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* Overlay */}
        <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute opacity-75 bg-gradient-to-r from-transparent to-primary-500" >

          <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute  z-10 bottom-0 opacity-50 bg-black">
          </div>
          <div className="h-[calc(100vh)] md:h-[400px]  w-screen absolute  bg-gradient-to-r z-10 bottom-0 from-black via-black/50  to-black">

          </div>
        </div>

        <main className="w-full flex justify-center mt-32 md:mt-0" >
          <div className="relative px-6 lg:px-0 max-w-7xl ">
            <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">
              <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-1 sm:gap-y-16 lg:gap-x-8 items-center " >

                <div className="text-center md:text-center p-4" >
                  <h1 className="text-3xl sm:text-4xl lg:text-6xl font-bold text-white">
                    DeepNeuronic: {t("Privacy Policy")}
                  </h1>
                </div>

                {/* Pattern */}
                <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                  <svg
                    className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                    viewBox="0 0 1155 678"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                      fillOpacity=".3"
                      d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                    />
                    <defs>
                      <linearGradient
                        id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                        x1="1155.49"
                        x2="-78.208"
                        y1=".177"
                        y2="474.645"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#3849ab" />
                        <stop offset={1} stopColor="#42a5f5" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>

              </div>
            </div>
          </div>
        </main>
      </section>

      <div className="flex items-center justify-center w-full gap-8" >
        <div className="w-32 text-primary-600 text-center cursor-pointer " >
        {t("Privacy Policy")}
        </div>

        <div onClick={handleNavigate}  className="w-32 dark:text-white text-center cursor-pointer" >
        {t("Terms and conditions")}
        </div>
      </div>

      <section className="w-full dark:bg-neutral-900 bg-white  p-20" >
        <div className="w-full mx-auto max-w-7xl   " >
          <h2 className="text-lg md:text-2xl" >{t("Privacy Policy")}</h2>
          <p>{t("Last updated")}: [{t("July")} 13, 2023]</p>
          {/* About */}
          <motion.div className="flex gap-6 items-center justify-between mt-4"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full ">
              <p>
                {t("policy_p1")}
              </p>
              <p className="mt-2" >
                {t("policy_p2")}
              </p>
            </motion.div>
          </motion.div>

          {/* vision */}
          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full"

            >
              <h3 className="text-lg md:text-2xl">
              {t("policy_t1")}
              </h3>
              <p className="mt-2" >
                {t("policy_p3")}
              </p>
              <p className="mt-2" >
                {t("policy_p4")}
              </p>

              <ul>
                <li>
                  - {t("policy_p4_1")}
                </li>
                <li>
                  - {t("policy_p4_2")}
                </li>
                <li>
                  - {t("policy_p4_3")}
                </li>
                <li>
                  - {t("policy_p4_4")}
                </li>
                <li>
                  - {t("policy_p4_5")}
                </li>
              </ul>

              <p className="mt-2" >
                {t("policy_p5")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t2")}
              </h3>

              <p className="mt-2" >
                {t("policy_p6")}
              </p>
              <p className="mt-2" >
                {t("policy_p7")}
              </p>
              <p className="mt-2" >
                {t("policy_p8")}
              </p>
              <p className="mt-2" >
                {t("policy_p9")}
              </p>

              <ul>
                <li>
                - {t("policy_p9_1")}
                </li>
                <li>
                - {t("policy_p9_2")}
                </li>
                <li>
                - {t("policy_p9_3")}
                </li>
                <li>
                - {t("policy_p9_4")}
                </li>
                <li>
                - {t("policy_p9_5")}
                </li>
                <li>
                - {t("policy_p9_6")}
                </li>
                <li>
                - {t("policy_p9_7")}
                </li>
                <li>
                - {t("policy_p9_8")}
                </li>
              </ul>
              <p className="mt-2" >
                {t("policy_p10")}
              </p>
              <p className="mt-2" >
                {t("policy_p11")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t3")}
              </h3>

              <p className="mt-2" >
                {t("policy_p12")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t4")}
              </h3>

              <p className="mt-2" >
                {t("policy_p13")}
              </p>
              <p className="mt-2" >
                {t("policy_p14")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t5")}
              </h3>

              <p className="mt-2" >
                {t("policy_p15")}
              </p>
              <p className="mt-2" >
                {t("policy_p16")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t6")}
              </h3>

              <p className="mt-2" >
                {t("policy_p17")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t7")}
              </h3>

              <p className="mt-2" >
                {t("policy_p18")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t8")}
              </h3>

              <p className="mt-2" >
                {t("policy_p19")}
              </p>
              <p className="mt-2" >
                {t("policy_p20")}
              </p>
              <p className="mt-2" >
                {t("policy_p21")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t9")}
              </h3>

              <p className="mt-2" >
                {t("policy_p22")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t10")}
              </h3>

              <p className="mt-2" >
                {t("policy_p23")}
              </p>
              <p className="mt-2" >
                {t("policy_p24")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t11")}
              </h3>
              <p className="mt-2" >
                {t("policy_p25")}
              </p>
            </motion.div>

          </motion.div>

          <motion.div className="mt-12 flex gap-6 items-center justify-between"
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.4,
              delay: 0.3
            }}
            viewport={{ once: true }}
          >
            <motion.div className="w-full">
              <h3 className="text-lg md:text-2xl">
              {t("policy_t12")}
              </h3>
              <p className="mt-2" >
                {t("policy_p26")}
              </p>

              <p className="mt-2" >
              https://www.deepneuronic.com/contact/
              </p>
              <p>
              UBIMEDICAL, Estrada Municipal 506, 6200-284 Covilhã, Portugal
              </p>
            </motion.div>

          </motion.div>
          
        </div>
      </section>

    
      <Footer />

    </div>
  );
}

import { useHistory } from 'react-router-dom';
import { ICONS, SOLUTIONS_MENU } from '../../resources/constants';
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { useState } from 'react';
import { classNames } from '../../utils';
import { useTranslation } from 'react-i18next';

export function Solutions({ }) {
  const history = useHistory();
  const [highlightedIndustry, setHighlightedIndustry] = useState<string | null>(null)
  const { t, i18n } = useTranslation();

  const handleIndustrySelection = (link: string) => {
    history.push(`${link}`);
  }

  const handleSolutionSelection = (link: string) => {
    history.push(`${link}`);
  }

  const exploreAllSolutions = () => {
    history.push("/solutions");
  }

  return (
    <div className=" flex whitespace-nowrap dark:bg-neutral-900 bg-white dark:text-neutral-100 text-neutral-600" >
      <div className="pt-11 px-12 pb-20 mr-4 dark:bg-neutral-800 bg-neutral-200 " >
        <h2 className="mb-4" >{t("Industries")}</h2>
        <ul className="mt-8">
          {SOLUTIONS_MENU.items.first_column.map((item) => (
            <li
              onMouseEnter={() => setHighlightedIndustry(item.id)}
              // onMouseLeave={() => setHighlightedIndustry(null)}
              onClick={() => handleIndustrySelection(item.link)}
              className={classNames(
                "font-bold text-sm cursor-pointer flex items-center mt-4 first:mt-0 group hover:text-primary-400",
                highlightedIndustry === item.id ? "text-primary-400" : ""
              )}
            >
              <span className=" w-12 h-12 rounded-full dark:bg-neutral-900 bg-white mr-2 p-1 flex items-center justify-center" >
                <img className=" w-7 group-hover:scale-110 duration-200 " src={item.icon} alt={item.name} />
              </span>
              {t(`${item.name}`)}
            </li>

          ))}
        </ul>
      </div>

      <div>
        <div className="flex " >
          <div className="px-10 mr-4 " >
            <ul className="mt-16" >
              {SOLUTIONS_MENU.items.second_column.map((item) => (
                <li
                  onClick={() => handleSolutionSelection(item.link)}
                  className={classNames(
                    `
                font-bold text-sm cursor-pointer flex items-center mt-4 first:mt-0 group
                hover:text-primary-400
              `,
                    highlightedIndustry === null && 'dark:text-neutral-100 text-neutral-600',
                    highlightedIndustry !== null && item.industries.includes(highlightedIndustry ?? "") ? '' : 'text-gray-500'

                  )}
                >
                  <span className=" w-8 h-8 rounded-full dark:bg-neutral-800 bg-neutral-200 mr-2 p-1 flex items-center justify-center" >
                    <img className=" w-4 group-hover:scale-150 duration-200 " src={item.icon} alt={item.name} />
                  </span>
                  {t(`${item.name}`)}
                </li>
              ))}
            </ul>
          </div>

          <div className="px-10 mr-4 " >
            <ul className="mt-16" >
              {SOLUTIONS_MENU.items.third_column.map((item) => (
                <li
                  onClick={() => handleSolutionSelection(item.link)}
                  className={classNames(
                    `
                    font-bold text-sm cursor-pointer flex items-center mt-4 first:mt-0 group
                  hover:text-primary-300
                    `,
                    highlightedIndustry === null && 'dark:text-neutral-100 text-neutral-600',
                    highlightedIndustry !== null && item.industries.includes(highlightedIndustry ?? "") ? '' : 'text-gray-500'

                  )}
                >
                  <span className=" w-8 h-8 rounded-full dark:bg-neutral-800 bg-neutral-200  mr-2 p-1 flex items-center justify-center" >
                    <img className=" w-4 group-hover:scale-150 duration-200 " src={item.icon} alt={item.name} />
                  </span>
                  {t(`${item.name}`)}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="w-full flex justify-end p-8 items-center text-gray-500 " >
          <div
            onClick={exploreAllSolutions}
            className="cursor-pointer flex hover:text-primary-500" >
            <p>
              {t("Explore our catalog")}
            </p>
            <ChevronRightIcon className="w-4 ml-1" />
          </div>
        </div>
      </div>
    </div>
  );
}

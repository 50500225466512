import { Button } from './Button'
import { Form } from '@unform/web'
import { Input } from './Input'
import * as Yup from 'yup';
import { useRef, useState } from 'react';
import { FormHandles, SubmitHandler } from '@unform/core';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { classNames } from '../utils';

type FormData = any

type TemplateData = {
  from_name: string;
  to_name: string;
  reply_to: any;
  company_name: any;
  description: string;
  message: string;
}

interface ContactFormProps {
  dark?: boolean
}

export default function ContactForm({ dark = false }: ContactFormProps) {
  const formRef = useRef<FormHandles>(null)
  const [textArea, setTextArea] = useState("")
  const { t, i18n } = useTranslation();
  const [pending, setPending] = useState(false)

  const emailPromise = (templateParams: TemplateData) => new Promise<any>((resolve, reject) => {
    emailjs.send('service_2uuxlib', 'template_5nes9op', templateParams, 'LbBP3hlXg90i3ntYi')
      .then((result) => {
        setTextArea("")
        resolve(result)
      }, (error) => {
        console.log(error.text);
        reject()
      });
  })

  const handleFormSubmit: SubmitHandler<FormData> = async (data, { reset }) => {
    if (pending) return
    setPending(true)
    try {
      const schema = Yup.object().shape({
        email: Yup.string().email().required(),
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        company: Yup.string().required(),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
      // Validation passed
      console.log(data);

      const templateParams = {
        recipient_emails: 'geral@deepneuronic.com, bruno.degardin@deepneuronic.com, vasco.lopes@deepneuronic.com',
        from_name: `${data.first_name} ${data.last_name}`,
        to_name: 'DeepNeuronic',
        reply_to: data.email,
        company_name: data.company,
        description: textArea,
        message: `First name: ${data.first_name}\nLast name: ${data.last_name}\nEmail: ${data.email}\nCompany: ${data.company}\nProject Description: ${textArea}\n`,
      };

      toast.promise(
        emailPromise(templateParams),
        {
          pending: t("Sending email..."),
          success: {
            render() {
              reset()
              return t("Email sent successfully!")
            },
            icon: "🟢",
          },
          error: t("Couldn't send email")
        }
      )
      setPending(false)

    } catch (err) {
      console.log(err)
      setPending(false)
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          //@ts-ignore
          validationErrors[error.path] = error.message;
        });
        formRef?.current?.setErrors(validationErrors);
      }
    }
  }

  return (
    <Form ref={formRef} onSubmit={handleFormSubmit}
      className={classNames(
        "w-[800px] rounded-2xl p-8",
        dark ? "bg-neutral-900" : "bg-white "
      )}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-6">
          <h2 className={classNames(
            "text-lg text-center font-bold leading-7 ",
            dark ? "text-gray-500" : "text-gray-900"
          )}>{t("contact_us")}</h2>
        </div>

        <div className="border-b border-gray-900/10 pb-12">
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-600">
                {t("First name")}
              </label>
              <div className="mt-2">
                <Input
                  type="text"
                  name="first_name"
                  id="first-name"
                  autoComplete="given-name"
                  className={classNames(
                    dark ? "bg-neutral-800 text-white  ring-gray-300" : ""
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-600">
                {t("Last name")}
              </label>
              <div className="mt-2">
                <Input
                  type="text"
                  name="last_name"
                  id="last-name"
                  autoComplete="family-name"
                  className={classNames(
                    dark ? "bg-neutral-800 text-white  ring-gray-300" : ""
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-600">
                {t("Work email")}
              </label>
              <div className="mt-2">
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className={classNames(
                    dark ? "bg-neutral-800 text-white  ring-gray-300" : ""
                  )}
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-600">
                {t("Company name")}
              </label>
              <div className="mt-2">
                <Input
                  id="company"
                  name="company"
                  type="text"
                  autoComplete="company-name"
                  className={classNames(
                    dark ? "bg-neutral-800 text-white  ring-gray-300" : ""
                  )}
                />
              </div>
            </div>

            <div className="col-span-full">
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-600">
                {t("Project description")}
              </label>
              <div className="mt-2">
                <textarea
                  id="about"
                  name="about"
                  rows={3}
                  placeholder={t("project_description_placeholder")}
                  defaultValue={''}
                  value={textArea}
                  onChange={(e) => setTextArea(e.target.value)}
                  className={classNames(
                    "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6",
                    dark ? "bg-neutral-800 text-white border-neutral-600 placeholder:text-gray-600 " : "placeholder:text-gray-400 "
                  )}
                />
              </div>
            </div>


          </div>
        </div>

        {/* <div className="border-b border-gray-900/10 pb-12">
          <p className="mt-1 text-base leading-6 text-gray-900">
            What can we do for you? Select all that apply
          </p>

          <div className="mt-4 space-y-10">
            <fieldset>
              <div className="space-y-6">

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="metadata"
                      name="metadata"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="metadata2" className="font-medium text-gray-900">
                      Metadata
                    </label>
                    <p className="text-gray-500">metadata description</p>
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="metadata2"
                      name="metadata2"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="metadata3" className="font-medium text-gray-900">
                      Metadata 2
                    </label>
                    <p className="text-gray-500">metadata description</p>
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="metadata3"
                      name="metadata3"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="metadata" className="font-medium text-gray-900">
                      Metadata 3
                    </label>
                    <p className="text-gray-500">metadata description</p>
                  </div>
                </div>

                <div className="relative flex gap-x-3">
                  <div className="flex h-6 items-center">
                    <input
                      id="other"
                      name="other"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                  </div>
                  <div className="text-sm leading-6">
                    <label htmlFor="other" className="font-medium text-gray-900">
                      Other
                    </label>
                  </div>
                </div>


              </div>
            </fieldset>

          </div>
        </div> */}
      </div>

      <div className="mt-6 flex items-center justify-center ">
        <Button
          primary
          size="large"
          type="submit"
          className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:cursor-not-allowed"
        >
          Submit
        </Button>
      </div>
    </Form>
  )
}

export const DELIMITED_AREAS = [
    {
        "ID": 0,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                746.55,
                285.66
            ],
            [
                722.25,
                301.86
            ],
            [
                756,
                303.21
            ],
            [
                780.3,
                289.71
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 1,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                805.14,
                288.36
            ],
            [
                780.84,
                304.56
            ],
            [
                821.34,
                304.56
            ],
            [
                838.89,
                292.41
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 2,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                861.84,
                288.36
            ],
            [
                837.54,
                304.56
            ],
            [
                871.29,
                305.91
            ],
            [
                895.59,
                292.41
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 3,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                982.53,
                288.36
            ],
            [
                958.23,
                304.56
            ],
            [
                991.98,
                305.91
            ],
            [
                1016.28,
                292.41
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 4,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                922.32,
                288.36
            ],
            [
                898.02,
                304.56
            ],
            [
                931.77,
                305.91
            ],
            [
                956.07,
                292.41
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 5,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                589.41,
                331.56
            ],
            [
                565.11,
                347.76
            ],
            [
                598.86,
                349.11
            ],
            [
                623.16,
                335.61
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 10,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                1198.26,
                295.11
            ],
            [
                1200.96,
                308.61
            ],
            [
                1242.81,
                309.96
            ],
            [
                1236.06,
                295.11
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 13,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                1145.61,
                291.06
            ],
            [
                1149.66,
                308.61
            ],
            [
                1186.11,
                309.96
            ],
            [
                1182.06,
                292.41
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 14,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                1084.86,
                292.41
            ],
            [
                1082.16,
                305.91
            ],
            [
                1124.01,
                304.56
            ],
            [
                1129.41,
                293.76
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 15,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                1020.06,
                292.41
            ],
            [
                1018.71,
                304.56
            ],
            [
                1063.26,
                305.91
            ],
            [
                1071.36,
                297.81
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 2017,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-2018 • CLASS",
        "POINTS": [
            [
                641.25,
                332.1
            ],
            [
                619.65,
                351
            ],
            [
                661.5,
                352.35
            ],
            [
                685.8,
                336.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 3018,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-3019 • CLASS",
        "POINTS": [
            [
                706.05,
                334.8
            ],
            [
                679.05,
                345.6
            ],
            [
                738.45,
                348.3
            ],
            [
                757.35,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 4019,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-4020 • CLASS",
        "POINTS": [
            [
                594,
                325.35
            ],
            [
                630.45,
                326.7
            ],
            [
                645.3,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 5020,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-5021 • CLASS",
        "POINTS": [
            [
                670.95,
                315.9
            ],
            [
                715.5,
                317.25
            ],
            [
                696.6,
                325.35
            ],
            [
                664.2,
                328.05
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 6021,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-6022 • CLASS",
        "POINTS": [
            [
                738.45,
                315.9
            ],
            [
                711.45,
                326.7
            ],
            [
                773.55,
                332.1
            ],
            [
                795.15,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 7022,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-7023 • CLASS",
        "POINTS": [
            [
                797.85,
                318.6
            ],
            [
                784.35,
                332.1
            ],
            [
                838.35,
                332.1
            ],
            [
                850.5,
                317.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 8023,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-8024 • CLASS",
        "POINTS": [
            [
                144.72,
                561.6
            ],
            [
                214.92,
                556.2
            ],
            [
                167.67,
                600.75
            ],
            [
                48.87,
                606.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 9024,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-9025 • CLASS",
        "POINTS": [
            [
                776.25,
                334.8
            ],
            [
                761.4,
                346.95
            ],
            [
                807.3,
                349.65
            ],
            [
                819.45,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 10025,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-10026 • CLASS",
        "POINTS": [
            [
                862.65,
                321.3
            ],
            [
                849.15,
                334.8
            ],
            [
                913.95,
                334.8
            ],
            [
                920.7,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 11026,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-11027 • CLASS",
        "POINTS": [
            [
                850.5,
                338.85
            ],
            [
                831.6,
                349.65
            ],
            [
                885.6,
                352.35
            ],
            [
                896.4,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 12027,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-12028 • CLASS",
        "POINTS": [
            [
                936.9,
                317.25
            ],
            [
                927.45,
                332.1
            ],
            [
                984.15,
                334.8
            ],
            [
                988.2,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 13028,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-13029 • CLASS",
        "POINTS": [
            [
                913.95,
                337.5
            ],
            [
                904.5,
                355.05
            ],
            [
                963.9,
                357.75
            ],
            [
                977.4,
                344.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 14029,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-14030 • CLASS",
        "POINTS": [
            [
                1000.35,
                317.25
            ],
            [
                996.3,
                332.1
            ],
            [
                1059.75,
                336.15
            ],
            [
                1061.1,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 15030,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-15031 • CLASS",
        "POINTS": [
            [
                994.95,
                338.85
            ],
            [
                985.5,
                353.7
            ],
            [
                1044.9,
                353.7
            ],
            [
                1057.05,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 16031,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-16032 • CLASS",
        "POINTS": [
            [
                1080,
                315.9
            ],
            [
                1125.9,
                321.3
            ],
            [
                1129.95,
                336.15
            ],
            [
                1077.3,
                332.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 17032,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-17033 • CLASS",
        "POINTS": [
            [
                1067.85,
                338.85
            ],
            [
                1066.5,
                353.7
            ],
            [
                1124.55,
                357.75
            ],
            [
                1128.6,
                342.9
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 18033,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-18034 • CLASS",
        "POINTS": [
            [
                1140.75,
                319.95
            ],
            [
                1140.75,
                336.15
            ],
            [
                1205.55,
                338.85
            ],
            [
                1193.4,
                321.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19034,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1138.05,
                336.15
            ],
            [
                1142.1,
                353.7
            ],
            [
                1198.8,
                356.4
            ],
            [
                1194.75,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19035,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1218.24,
                344.25
            ],
            [
                1216.89,
                353.7
            ],
            [
                1273.59,
                353.7
            ],
            [
                1265.49,
                342.9
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19036,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1273.59,
                318.6
            ],
            [
                1287.09,
                334.8
            ],
            [
                1332.99,
                332.1
            ],
            [
                1320.84,
                317.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19037,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1208.79,
                318.6
            ],
            [
                1215.54,
                333.45
            ],
            [
                1260.09,
                333.45
            ],
            [
                1251.99,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 20038,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-20039 • CLASS",
        "POINTS": [
            [
                1283.85,
                342.9
            ],
            [
                1296,
                357.75
            ],
            [
                1351.35,
                353.7
            ],
            [
                1343.25,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 21039,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-21040 • CLASS",
        "POINTS": [
            [
                1344.6,
                325.35
            ],
            [
                1354.05,
                337.5
            ],
            [
                1416.15,
                334.8
            ],
            [
                1401.3,
                322.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 22040,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-22041 • CLASS",
        "POINTS": [
            [
                1359.45,
                348.3
            ],
            [
                1366.2,
                357.75
            ],
            [
                1426.95,
                356.4
            ],
            [
                1420.2,
                346.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 23041,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-23042 • CLASS",
        "POINTS": [
            [
                436.05,
                414.45
            ],
            [
                387.45,
                436.05
            ],
            [
                330.75,
                430.65
            ],
            [
                380.7,
                410.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 24042,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-24043 • CLASS",
        "POINTS": [
            [
                457.65,
                417.15
            ],
            [
                405,
                434.7
            ],
            [
                490.05,
                438.75
            ],
            [
                518.4,
                418.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 25043,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-25044 • CLASS",
        "POINTS": [
            [
                557.55,
                414.45
            ],
            [
                508.95,
                436.05
            ],
            [
                587.25,
                438.75
            ],
            [
                625.05,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 26044,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-26045 • CLASS",
        "POINTS": [
            [
                646.65,
                417.15
            ],
            [
                608.85,
                438.75
            ],
            [
                692.55,
                438.75
            ],
            [
                719.55,
                417.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 27045,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-27046 • CLASS",
        "POINTS": [
            [
                743.85,
                417.15
            ],
            [
                711.45,
                438.75
            ],
            [
                803.25,
                436.05
            ],
            [
                822.15,
                417.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 28046,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-28047 • CLASS",
        "POINTS": [
            [
                839.7,
                413.1
            ],
            [
                822.15,
                436.05
            ],
            [
                912.6,
                437.4
            ],
            [
                926.1,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 29047,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-29048 • CLASS",
        "POINTS": [
            [
                946.35,
                411.75
            ],
            [
                935.55,
                438.75
            ],
            [
                1019.25,
                438.75
            ],
            [
                1027.35,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 30048,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-30049 • CLASS",
        "POINTS": [
            [
                1043.55,
                417.15
            ],
            [
                1035.45,
                441.45
            ],
            [
                1127.25,
                441.45
            ],
            [
                1127.25,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 31049,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-31050 • CLASS",
        "POINTS": [
            [
                1148.85,
                414.45
            ],
            [
                1148.85,
                441.45
            ],
            [
                1246.05,
                441.45
            ],
            [
                1235.25,
                411.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 32050,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-32051 • CLASS",
        "POINTS": [
            [
                1254.15,
                419.85
            ],
            [
                1259.55,
                441.45
            ],
            [
                1344.6,
                440.1
            ],
            [
                1336.5,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 33051,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-33052 • CLASS",
        "POINTS": [
            [
                1351.35,
                417.15
            ],
            [
                1367.55,
                444.15
            ],
            [
                1456.65,
                444.15
            ],
            [
                1425.6,
                413.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 34052,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-34053 • CLASS",
        "POINTS": [
            [
                1456.65,
                422.55
            ],
            [
                1475.55,
                444.15
            ],
            [
                1572.75,
                446.85
            ],
            [
                1543.05,
                419.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 35053,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-35054 • CLASS",
        "POINTS": [
            [
                1564.65,
                417.15
            ],
            [
                1591.65,
                446.85
            ],
            [
                1676.7,
                445.5
            ],
            [
                1628.1,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 36054,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-36055 • CLASS",
        "POINTS": [
            [
                518.4,
                372.6
            ],
            [
                460.35,
                371.25
            ],
            [
                436.05,
                387.45
            ],
            [
                490.05,
                390.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 37055,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-37056 • CLASS",
        "POINTS": [
            [
                549.45,
                373.95
            ],
            [
                511.65,
                390.15
            ],
            [
                569.7,
                391.5
            ],
            [
                603.45,
                376.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 38056,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-38057 • CLASS",
        "POINTS": [
            [
                630.45,
                376.65
            ],
            [
                598.05,
                392.85
            ],
            [
                666.9,
                391.5
            ],
            [
                693.9,
                375.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 39057,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-39058 • CLASS",
        "POINTS": [
            [
                711.45,
                379.35
            ],
            [
                687.15,
                392.85
            ],
            [
                749.25,
                392.85
            ],
            [
                760.05,
                379.35
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 40058,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-40059 • CLASS",
        "POINTS": [
            [
                816.75,
                371.25
            ],
            [
                810,
                391.5
            ],
            [
                854.55,
                395.55
            ],
            [
                862.65,
                373.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 41059,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-41060 • CLASS",
        "POINTS": [
            [
                1698.3,
                444.15
            ],
            [
                1776.6,
                446.85
            ],
            [
                1732.05,
                421.2
            ],
            [
                1665.9,
                425.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 42060,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-42061 • CLASS",
        "POINTS": [
            [
                1761.75,
                419.85
            ],
            [
                1799.55,
                441.45
            ],
            [
                1862.73,
                440.1
            ],
            [
                1833.03,
                418.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 43061,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-43062 • CLASS",
        "POINTS": [
            [
                878.85,
                378
            ],
            [
                868.05,
                396.9
            ],
            [
                939.6,
                400.95
            ],
            [
                940.95,
                383.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 45063,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-45064 • CLASS",
        "POINTS": [
            [
                1683.45,
                380.7
            ],
            [
                1707.75,
                396.9
            ],
            [
                1786.05,
                394.2
            ],
            [
                1733.4,
                373.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 46064,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-46065 • CLASS",
        "POINTS": [
            [
                1426.95,
                369.9
            ],
            [
                1443.15,
                394.2
            ],
            [
                1505.25,
                396.9
            ],
            [
                1485,
                371.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 47065,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-47066 • CLASS",
        "POINTS": [
            [
                1510.65,
                378
            ],
            [
                1526.85,
                391.5
            ],
            [
                1571.4,
                390.15
            ],
            [
                1557.9,
                376.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 48066,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-48067 • CLASS",
        "POINTS": [
            [
                105.84,
                552.15
            ],
            [
                24.3,
                608.85
            ],
            [
                0,
                607.5
            ],
            [
                0,
                537.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 50068,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-50069 • CLASS",
        "POINTS": [
            [
                267.3,
                576.45
            ],
            [
                198.45,
                612.9
            ],
            [
                298.35,
                615.6
            ],
            [
                344.25,
                580.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 51069,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-51070 • CLASS",
        "POINTS": [
            [
                380.7,
                579.15
            ],
            [
                329.4,
                619.65
            ],
            [
                446.85,
                623.7
            ],
            [
                495.45,
                583.2
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 52070,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-52071 • CLASS",
        "POINTS": [
            [
                545.4,
                573.75
            ],
            [
                495.45,
                621
            ],
            [
                614.25,
                621
            ],
            [
                648,
                579.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 53071,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-53072 • CLASS",
        "POINTS": [
            [
                704.7,
                562.95
            ],
            [
                646.65,
                623.7
            ],
            [
                776.25,
                626.4
            ],
            [
                819.45,
                564.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 54072,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-54073 • CLASS",
        "POINTS": [
            [
                854.55,
                510.3
            ],
            [
                827.55,
                550.8
            ],
            [
                739.8,
                546.75
            ],
            [
                758.7,
                511.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 55073,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-55074 • CLASS",
        "POINTS": [
            [
                886.95,
                515.7
            ],
            [
                862.65,
                548.1
            ],
            [
                973.35,
                548.1
            ],
            [
                986.85,
                513
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 56074,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-56075 • CLASS",
        "POINTS": [
            [
                847.8,
                565.65
            ],
            [
                815.4,
                622.35
            ],
            [
                949.05,
                626.4
            ],
            [
                970.65,
                567
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 57075,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-57076 • CLASS",
        "POINTS": [
            [
                1011.15,
                504.9
            ],
            [
                1001.7,
                552.15
            ],
            [
                1127.25,
                553.5
            ],
            [
                1124.55,
                507.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 58076,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-58077 • CLASS",
        "POINTS": [
            [
                1003.05,
                564.3
            ],
            [
                984.15,
                623.7
            ],
            [
                1117.8,
                625.05
            ],
            [
                1124.55,
                567
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 59077,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-59078 • CLASS",
        "POINTS": [
            [
                1142.1,
                487.35
            ],
            [
                1143.45,
                545.4
            ],
            [
                1267.65,
                548.1
            ],
            [
                1258.2,
                490.05
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 60078,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-60079 • CLASS",
        "POINTS": [
            [
                1143.45,
                569.7
            ],
            [
                1146.15,
                629.1
            ],
            [
                1285.2,
                627.75
            ],
            [
                1275.75,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 61079,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-61080 • CLASS",
        "POINTS": [
            [
                1278.45,
                515.7
            ],
            [
                1289.25,
                553.5
            ],
            [
                1416.15,
                553.5
            ],
            [
                1387.8,
                514.35
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 62080,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-62081 • CLASS",
        "POINTS": [
            [
                1305.45,
                575.1
            ],
            [
                1321.65,
                634.5
            ],
            [
                1456.65,
                629.1
            ],
            [
                1424.25,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 63081,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-63082 • CLASS",
        "POINTS": [
            [
                1448.55,
                567
            ],
            [
                1489.05,
                631.8
            ],
            [
                1614.6,
                627.75
            ],
            [
                1567.35,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 64082,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-64083 • CLASS",
        "POINTS": [
            [
                1416.15,
                521.1
            ],
            [
                1435.05,
                558.9
            ],
            [
                1553.85,
                556.2
            ],
            [
                1517.4,
                519.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 65083,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-65084 • CLASS",
        "POINTS": [
            [
                1540.35,
                513
            ],
            [
                1578.15,
                558.9
            ],
            [
                1694.25,
                556.2
            ],
            [
                1651.05,
                513
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 66084,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-66085 • CLASS",
        "POINTS": [
            [
                1594.35,
                572.4
            ],
            [
                1647,
                630.45
            ],
            [
                1771.2,
                630.45
            ],
            [
                1706.4,
                573.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 67085,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-67086 • CLASS",
        "POINTS": [
            [
                1667.25,
                510.3
            ],
            [
                1752.3,
                508.95
            ],
            [
                1827.63,
                557.55
            ],
            [
                1725.3,
                554.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 68086,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-68087 • CLASS",
        "POINTS": [
            [
                1750.95,
                575.1
            ],
            [
                1823.85,
                634.5
            ],
            [
                1915.38,
                631.8
            ],
            [
                1854.63,
                579.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 69087,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-69088 • CLASS",
        "POINTS": [
            [
                1813.05,
                507.6
            ],
            [
                1861.38,
                550.8
            ],
            [
                1915.38,
                548.1
            ],
            [
                1881.63,
                511.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 70088,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-70089 • CLASS",
        "POINTS": [
            [
                0,
                926.1
            ],
            [
                73.17,
                930.15
            ],
            [
                187.65,
                801.9
            ],
            [
                50.22,
                785.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 71089,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-71090 • CLASS",
        "POINTS": [
            [
                244.35,
                801.9
            ],
            [
                112.05,
                931.5
            ],
            [
                322.65,
                939.6
            ],
            [
                403.65,
                818.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 72090,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-72091 • CLASS",
        "POINTS": [
            [
                480.6,
                822.15
            ],
            [
                375.3,
                946.35
            ],
            [
                565.65,
                963.9
            ],
            [
                646.65,
                826.2
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 73091,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-73092 • CLASS",
        "POINTS": [
            [
                685.8,
                822.15
            ],
            [
                610.2,
                959.85
            ],
            [
                831.6,
                970.65
            ],
            [
                881.55,
                831.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 74092,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-74093 • CLASS",
        "POINTS": [
            [
                922.05,
                828.9
            ],
            [
                892.35,
                963.9
            ],
            [
                1113.75,
                980.1
            ],
            [
                1116.45,
                839.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 75093,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-75094 • CLASS",
        "POINTS": [
            [
                1154.25,
                834.3
            ],
            [
                1156.95,
                977.4
            ],
            [
                1383.75,
                977.4
            ],
            [
                1345.95,
                834.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 76094,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-76095 • CLASS",
        "POINTS": [
            [
                1391.85,
                839.7
            ],
            [
                1431,
                984.15
            ],
            [
                1647,
                978.75
            ],
            [
                1568.7,
                835.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 77095,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-77096 • CLASS",
        "POINTS": [
            [
                1624.05,
                831.6
            ],
            [
                1707.75,
                972
            ],
            [
                1907.28,
                972
            ],
            [
                1799.55,
                831.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 78096,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-78097 • CLASS",
        "POINTS": [
            [
                1861.38,
                834.3
            ],
            [
                1912.68,
                923.4
            ],
            [
                1915.38,
                812.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 79097,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-79098 • CLASS",
        "POINTS": [
            [
                82.35,
                969.3
            ],
            [
                271.35,
                961.2
            ],
            [
                163.35,
                1080
            ],
            [
                0,
                1080
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 80098,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-80099 • CLASS",
        "POINTS": [
            [
                530.55,
                996.3
            ],
            [
                477.9,
                1080
            ],
            [
                263.25,
                1080
            ],
            [
                353.7,
                970.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 81099,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-81100 • CLASS",
        "POINTS": [
            [
                587.25,
                999
            ],
            [
                538.65,
                1077.3
            ],
            [
                797.85,
                1077.3
            ],
            [
                811.35,
                1007.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 82100,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-82101 • CLASS",
        "POINTS": [
            [
                878.85,
                1015.2
            ],
            [
                862.65,
                1074.6
            ],
            [
                1097.55,
                1074.6
            ],
            [
                1097.55,
                1012.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 83101,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-83102 • CLASS",
        "POINTS": [
            [
                1151.55,
                1023.3
            ],
            [
                1151.55,
                1077.3
            ],
            [
                1408.05,
                1071.9
            ],
            [
                1389.15,
                1007.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 84102,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-84103 • CLASS",
        "POINTS": [
            [
                1445.85,
                1020.6
            ],
            [
                1458,
                1080
            ],
            [
                1710.45,
                1074.6
            ],
            [
                1680.75,
                1004.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    }
]


const DELIMITED_AREAS2 = [
    {
        "ID": 5,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-1 • CLASS",
        "POINTS": [
            [
                589.41,
                331.56
            ],
            [
                565.11,
                347.76
            ],
            [
                598.86,
                349.11
            ],
            [
                623.16,
                335.61
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 2017,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-2018 • CLASS",
        "POINTS": [
            [
                641.25,
                332.1
            ],
            [
                619.65,
                351
            ],
            [
                661.5,
                352.35
            ],
            [
                685.8,
                336.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 3018,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-3019 • CLASS",
        "POINTS": [
            [
                706.05,
                334.8
            ],
            [
                679.05,
                345.6
            ],
            [
                738.45,
                348.3
            ],
            [
                757.35,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 4019,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-4020 • CLASS",
        "POINTS": [
            [
                594,
                325.35
            ],
            [
                630.45,
                326.7
            ],
            [
                645.3,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 5020,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-5021 • CLASS",
        "POINTS": [
            [
                670.95,
                315.9
            ],
            [
                715.5,
                317.25
            ],
            [
                696.6,
                325.35
            ],
            [
                664.2,
                328.05
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 6021,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-6022 • CLASS",
        "POINTS": [
            [
                738.45,
                315.9
            ],
            [
                711.45,
                326.7
            ],
            [
                773.55,
                332.1
            ],
            [
                795.15,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 7022,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-7023 • CLASS",
        "POINTS": [
            [
                797.85,
                318.6
            ],
            [
                784.35,
                332.1
            ],
            [
                838.35,
                332.1
            ],
            [
                850.5,
                317.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 8023,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-8024 • CLASS",
        "POINTS": [
            [
                144.72,
                561.6
            ],
            [
                214.92,
                556.2
            ],
            [
                167.67,
                600.75
            ],
            [
                48.87,
                606.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 9024,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-9025 • CLASS",
        "POINTS": [
            [
                776.25,
                334.8
            ],
            [
                761.4,
                346.95
            ],
            [
                807.3,
                349.65
            ],
            [
                819.45,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 10025,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-10026 • CLASS",
        "POINTS": [
            [
                862.65,
                321.3
            ],
            [
                849.15,
                334.8
            ],
            [
                913.95,
                334.8
            ],
            [
                920.7,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 11026,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-11027 • CLASS",
        "POINTS": [
            [
                850.5,
                338.85
            ],
            [
                831.6,
                349.65
            ],
            [
                885.6,
                352.35
            ],
            [
                896.4,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 12027,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-12028 • CLASS",
        "POINTS": [
            [
                936.9,
                317.25
            ],
            [
                927.45,
                332.1
            ],
            [
                984.15,
                334.8
            ],
            [
                988.2,
                319.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 13028,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-13029 • CLASS",
        "POINTS": [
            [
                913.95,
                337.5
            ],
            [
                904.5,
                355.05
            ],
            [
                963.9,
                357.75
            ],
            [
                977.4,
                344.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 14029,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-14030 • CLASS",
        "POINTS": [
            [
                1000.35,
                317.25
            ],
            [
                996.3,
                332.1
            ],
            [
                1059.75,
                336.15
            ],
            [
                1061.1,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 15030,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-15031 • CLASS",
        "POINTS": [
            [
                994.95,
                338.85
            ],
            [
                985.5,
                353.7
            ],
            [
                1044.9,
                353.7
            ],
            [
                1057.05,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 16031,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-16032 • CLASS",
        "POINTS": [
            [
                1080,
                315.9
            ],
            [
                1125.9,
                321.3
            ],
            [
                1129.95,
                336.15
            ],
            [
                1077.3,
                332.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 17032,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-17033 • CLASS",
        "POINTS": [
            [
                1067.85,
                338.85
            ],
            [
                1066.5,
                353.7
            ],
            [
                1124.55,
                357.75
            ],
            [
                1128.6,
                342.9
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 18033,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-18034 • CLASS",
        "POINTS": [
            [
                1140.75,
                319.95
            ],
            [
                1140.75,
                336.15
            ],
            [
                1205.55,
                338.85
            ],
            [
                1193.4,
                321.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19034,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1138.05,
                336.15
            ],
            [
                1142.1,
                353.7
            ],
            [
                1198.8,
                356.4
            ],
            [
                1194.75,
                338.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19035,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1218.24,
                344.25
            ],
            [
                1216.89,
                353.7
            ],
            [
                1273.59,
                353.7
            ],
            [
                1265.49,
                342.9
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19036,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1273.59,
                318.6
            ],
            [
                1287.09,
                334.8
            ],
            [
                1332.99,
                332.1
            ],
            [
                1320.84,
                317.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 19037,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-19035 • CLASS",
        "POINTS": [
            [
                1208.79,
                318.6
            ],
            [
                1215.54,
                333.45
            ],
            [
                1260.09,
                333.45
            ],
            [
                1251.99,
                318.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 20038,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-20039 • CLASS",
        "POINTS": [
            [
                1283.85,
                342.9
            ],
            [
                1296,
                357.75
            ],
            [
                1351.35,
                353.7
            ],
            [
                1343.25,
                337.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 21039,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-21040 • CLASS",
        "POINTS": [
            [
                1344.6,
                325.35
            ],
            [
                1354.05,
                337.5
            ],
            [
                1416.15,
                334.8
            ],
            [
                1401.3,
                322.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 22040,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-22041 • CLASS",
        "POINTS": [
            [
                1359.45,
                348.3
            ],
            [
                1366.2,
                357.75
            ],
            [
                1426.95,
                356.4
            ],
            [
                1420.2,
                346.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 23041,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-23042 • CLASS",
        "POINTS": [
            [
                436.05,
                414.45
            ],
            [
                387.45,
                436.05
            ],
            [
                330.75,
                430.65
            ],
            [
                380.7,
                410.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 24042,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-24043 • CLASS",
        "POINTS": [
            [
                457.65,
                417.15
            ],
            [
                405,
                434.7
            ],
            [
                490.05,
                438.75
            ],
            [
                518.4,
                418.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 25043,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-25044 • CLASS",
        "POINTS": [
            [
                557.55,
                414.45
            ],
            [
                508.95,
                436.05
            ],
            [
                587.25,
                438.75
            ],
            [
                625.05,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 26044,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-26045 • CLASS",
        "POINTS": [
            [
                646.65,
                417.15
            ],
            [
                608.85,
                438.75
            ],
            [
                692.55,
                438.75
            ],
            [
                719.55,
                417.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 27045,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-27046 • CLASS",
        "POINTS": [
            [
                743.85,
                417.15
            ],
            [
                711.45,
                438.75
            ],
            [
                803.25,
                436.05
            ],
            [
                822.15,
                417.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 28046,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-28047 • CLASS",
        "POINTS": [
            [
                839.7,
                413.1
            ],
            [
                822.15,
                436.05
            ],
            [
                912.6,
                437.4
            ],
            [
                926.1,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 29047,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-29048 • CLASS",
        "POINTS": [
            [
                946.35,
                411.75
            ],
            [
                935.55,
                438.75
            ],
            [
                1019.25,
                438.75
            ],
            [
                1027.35,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 30048,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-30049 • CLASS",
        "POINTS": [
            [
                1043.55,
                417.15
            ],
            [
                1035.45,
                441.45
            ],
            [
                1127.25,
                441.45
            ],
            [
                1127.25,
                414.45
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 31049,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-31050 • CLASS",
        "POINTS": [
            [
                1148.85,
                414.45
            ],
            [
                1148.85,
                441.45
            ],
            [
                1246.05,
                441.45
            ],
            [
                1235.25,
                411.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 32050,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-32051 • CLASS",
        "POINTS": [
            [
                1254.15,
                419.85
            ],
            [
                1259.55,
                441.45
            ],
            [
                1344.6,
                440.1
            ],
            [
                1336.5,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 33051,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-33052 • CLASS",
        "POINTS": [
            [
                1351.35,
                417.15
            ],
            [
                1367.55,
                444.15
            ],
            [
                1456.65,
                444.15
            ],
            [
                1425.6,
                413.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 34052,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-34053 • CLASS",
        "POINTS": [
            [
                1456.65,
                422.55
            ],
            [
                1475.55,
                444.15
            ],
            [
                1572.75,
                446.85
            ],
            [
                1543.05,
                419.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 35053,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-35054 • CLASS",
        "POINTS": [
            [
                1564.65,
                417.15
            ],
            [
                1591.65,
                446.85
            ],
            [
                1676.7,
                445.5
            ],
            [
                1628.1,
                415.8
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 36054,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-36055 • CLASS",
        "POINTS": [
            [
                518.4,
                372.6
            ],
            [
                460.35,
                371.25
            ],
            [
                436.05,
                387.45
            ],
            [
                490.05,
                390.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 37055,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-37056 • CLASS",
        "POINTS": [
            [
                549.45,
                373.95
            ],
            [
                511.65,
                390.15
            ],
            [
                569.7,
                391.5
            ],
            [
                603.45,
                376.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 38056,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-38057 • CLASS",
        "POINTS": [
            [
                630.45,
                376.65
            ],
            [
                598.05,
                392.85
            ],
            [
                666.9,
                391.5
            ],
            [
                693.9,
                375.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 39057,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-39058 • CLASS",
        "POINTS": [
            [
                711.45,
                379.35
            ],
            [
                687.15,
                392.85
            ],
            [
                749.25,
                392.85
            ],
            [
                760.05,
                379.35
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 40058,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-40059 • CLASS",
        "POINTS": [
            [
                816.75,
                371.25
            ],
            [
                810,
                391.5
            ],
            [
                854.55,
                395.55
            ],
            [
                862.65,
                373.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 41059,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-41060 • CLASS",
        "POINTS": [
            [
                1698.3,
                444.15
            ],
            [
                1776.6,
                446.85
            ],
            [
                1732.05,
                421.2
            ],
            [
                1665.9,
                425.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 42060,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-42061 • CLASS",
        "POINTS": [
            [
                1761.75,
                419.85
            ],
            [
                1799.55,
                441.45
            ],
            [
                1862.73,
                440.1
            ],
            [
                1833.03,
                418.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 43061,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-43062 • CLASS",
        "POINTS": [
            [
                878.85,
                378
            ],
            [
                868.05,
                396.9
            ],
            [
                939.6,
                400.95
            ],
            [
                940.95,
                383.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 45063,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-45064 • CLASS",
        "POINTS": [
            [
                1683.45,
                380.7
            ],
            [
                1707.75,
                396.9
            ],
            [
                1786.05,
                394.2
            ],
            [
                1733.4,
                373.95
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 46064,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-46065 • CLASS",
        "POINTS": [
            [
                1426.95,
                369.9
            ],
            [
                1443.15,
                394.2
            ],
            [
                1505.25,
                396.9
            ],
            [
                1485,
                371.25
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 47065,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-47066 • CLASS",
        "POINTS": [
            [
                1510.65,
                378
            ],
            [
                1526.85,
                391.5
            ],
            [
                1571.4,
                390.15
            ],
            [
                1557.9,
                376.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 48066,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-48067 • CLASS",
        "POINTS": [
            [
                105.84,
                552.15
            ],
            [
                24.3,
                608.85
            ],
            [
                0,
                607.5
            ],
            [
                0,
                537.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 50068,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-50069 • CLASS",
        "POINTS": [
            [
                267.3,
                576.45
            ],
            [
                198.45,
                612.9
            ],
            [
                298.35,
                615.6
            ],
            [
                344.25,
                580.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 51069,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-51070 • CLASS",
        "POINTS": [
            [
                380.7,
                579.15
            ],
            [
                329.4,
                619.65
            ],
            [
                446.85,
                623.7
            ],
            [
                495.45,
                583.2
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 52070,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-52071 • CLASS",
        "POINTS": [
            [
                545.4,
                573.75
            ],
            [
                495.45,
                621
            ],
            [
                614.25,
                621
            ],
            [
                648,
                579.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 53071,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-53072 • CLASS",
        "POINTS": [
            [
                704.7,
                562.95
            ],
            [
                646.65,
                623.7
            ],
            [
                776.25,
                626.4
            ],
            [
                819.45,
                564.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 54072,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-54073 • CLASS",
        "POINTS": [
            [
                854.55,
                510.3
            ],
            [
                827.55,
                550.8
            ],
            [
                739.8,
                546.75
            ],
            [
                758.7,
                511.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 55073,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-55074 • CLASS",
        "POINTS": [
            [
                886.95,
                515.7
            ],
            [
                862.65,
                548.1
            ],
            [
                973.35,
                548.1
            ],
            [
                986.85,
                513
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 56074,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-56075 • CLASS",
        "POINTS": [
            [
                847.8,
                565.65
            ],
            [
                815.4,
                622.35
            ],
            [
                949.05,
                626.4
            ],
            [
                970.65,
                567
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 57075,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-57076 • CLASS",
        "POINTS": [
            [
                1011.15,
                504.9
            ],
            [
                1001.7,
                552.15
            ],
            [
                1127.25,
                553.5
            ],
            [
                1124.55,
                507.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 58076,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-58077 • CLASS",
        "POINTS": [
            [
                1003.05,
                564.3
            ],
            [
                984.15,
                623.7
            ],
            [
                1117.8,
                625.05
            ],
            [
                1124.55,
                567
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 59077,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-59078 • CLASS",
        "POINTS": [
            [
                1142.1,
                487.35
            ],
            [
                1143.45,
                545.4
            ],
            [
                1267.65,
                548.1
            ],
            [
                1258.2,
                490.05
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 60078,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-60079 • CLASS",
        "POINTS": [
            [
                1143.45,
                569.7
            ],
            [
                1146.15,
                629.1
            ],
            [
                1285.2,
                627.75
            ],
            [
                1275.75,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 61079,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-61080 • CLASS",
        "POINTS": [
            [
                1278.45,
                515.7
            ],
            [
                1289.25,
                553.5
            ],
            [
                1416.15,
                553.5
            ],
            [
                1387.8,
                514.35
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 62080,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-62081 • CLASS",
        "POINTS": [
            [
                1305.45,
                575.1
            ],
            [
                1321.65,
                634.5
            ],
            [
                1456.65,
                629.1
            ],
            [
                1424.25,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 63081,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-63082 • CLASS",
        "POINTS": [
            [
                1448.55,
                567
            ],
            [
                1489.05,
                631.8
            ],
            [
                1614.6,
                627.75
            ],
            [
                1567.35,
                569.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 64082,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-64083 • CLASS",
        "POINTS": [
            [
                1416.15,
                521.1
            ],
            [
                1435.05,
                558.9
            ],
            [
                1553.85,
                556.2
            ],
            [
                1517.4,
                519.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 65083,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-65084 • CLASS",
        "POINTS": [
            [
                1540.35,
                513
            ],
            [
                1578.15,
                558.9
            ],
            [
                1694.25,
                556.2
            ],
            [
                1651.05,
                513
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 66084,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-66085 • CLASS",
        "POINTS": [
            [
                1594.35,
                572.4
            ],
            [
                1647,
                630.45
            ],
            [
                1771.2,
                630.45
            ],
            [
                1706.4,
                573.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 67085,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-67086 • CLASS",
        "POINTS": [
            [
                1667.25,
                510.3
            ],
            [
                1752.3,
                508.95
            ],
            [
                1827.63,
                557.55
            ],
            [
                1725.3,
                554.85
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 68086,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-68087 • CLASS",
        "POINTS": [
            [
                1750.95,
                575.1
            ],
            [
                1823.85,
                634.5
            ],
            [
                1915.38,
                631.8
            ],
            [
                1854.63,
                579.15
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 69087,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-69088 • CLASS",
        "POINTS": [
            [
                1813.05,
                507.6
            ],
            [
                1861.38,
                550.8
            ],
            [
                1915.38,
                548.1
            ],
            [
                1881.63,
                511.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 70088,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-70089 • CLASS",
        "POINTS": [
            [
                0,
                926.1
            ],
            [
                73.17,
                930.15
            ],
            [
                187.65,
                801.9
            ],
            [
                50.22,
                785.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 71089,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-71090 • CLASS",
        "POINTS": [
            [
                244.35,
                801.9
            ],
            [
                112.05,
                931.5
            ],
            [
                322.65,
                939.6
            ],
            [
                403.65,
                818.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 72090,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-72091 • CLASS",
        "POINTS": [
            [
                480.6,
                822.15
            ],
            [
                375.3,
                946.35
            ],
            [
                565.65,
                963.9
            ],
            [
                646.65,
                826.2
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 73091,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-73092 • CLASS",
        "POINTS": [
            [
                685.8,
                822.15
            ],
            [
                610.2,
                959.85
            ],
            [
                831.6,
                970.65
            ],
            [
                881.55,
                831.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 74092,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-74093 • CLASS",
        "POINTS": [
            [
                922.05,
                828.9
            ],
            [
                892.35,
                963.9
            ],
            [
                1113.75,
                980.1
            ],
            [
                1116.45,
                839.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 75093,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-75094 • CLASS",
        "POINTS": [
            [
                1154.25,
                834.3
            ],
            [
                1156.95,
                977.4
            ],
            [
                1383.75,
                977.4
            ],
            [
                1345.95,
                834.3
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 76094,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-76095 • CLASS",
        "POINTS": [
            [
                1391.85,
                839.7
            ],
            [
                1431,
                984.15
            ],
            [
                1647,
                978.75
            ],
            [
                1568.7,
                835.65
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 77095,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-77096 • CLASS",
        "POINTS": [
            [
                1624.05,
                831.6
            ],
            [
                1707.75,
                972
            ],
            [
                1907.28,
                972
            ],
            [
                1799.55,
                831.6
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 78096,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-78097 • CLASS",
        "POINTS": [
            [
                1861.38,
                834.3
            ],
            [
                1912.68,
                923.4
            ],
            [
                1915.38,
                812.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 79097,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-79098 • CLASS",
        "POINTS": [
            [
                82.35,
                969.3
            ],
            [
                259.2,
                978.75
            ],
            [
                163.35,
                1080
            ],
            [
                0,
                1059.75
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 80098,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-80099 • CLASS",
        "POINTS": [
            [
                529.2,
                994.95
            ],
            [
                477.9,
                1080
            ],
            [
                263.25,
                1080
            ],
            [
                349.65,
                974.7
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 81099,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-81100 • CLASS",
        "POINTS": [
            [
                587.25,
                999
            ],
            [
                538.65,
                1077.3
            ],
            [
                797.85,
                1077.3
            ],
            [
                811.35,
                1007.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 82100,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-82101 • CLASS",
        "POINTS": [
            [
                878.85,
                1015.2
            ],
            [
                862.65,
                1074.6
            ],
            [
                1097.55,
                1074.6
            ],
            [
                1097.55,
                1012.5
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 83101,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-83102 • CLASS",
        "POINTS": [
            [
                1151.55,
                1023.3
            ],
            [
                1151.55,
                1077.3
            ],
            [
                1408.05,
                1071.9
            ],
            [
                1389.15,
                1007.1
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    },
    {
        "ID": 84102,
        "EVENT_TYPE": "",
        "TARGET_CLASS_NAME": "transgressing-84103 • CLASS",
        "POINTS": [
            [
                1445.85,
                1020.6
            ],
            [
                1458,
                1080
            ],
            [
                1710.45,
                1074.6
            ],
            [
                1680.75,
                1004.4
            ]
        ],
        "COLOUR": [
            64,
            249,
            103,
            1
        ]
    }
]
import { motion } from 'framer-motion'
import * as MyButton from './Button';
import { classNames } from '../utils';
import { useEffect, useState } from 'react';
import { INDUSTRIES, USE_CASES } from '../resources/constants';
import { useHistory } from 'react-router-dom';
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon, Bars3Icon, XMarkIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import usePagination from '../hooks/usePagination';
import { Disclosure } from '@headlessui/react';
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useTranslation } from 'react-i18next';

interface SolutionsMenuProps {
}

const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}

const PER_PAGE_DESKTOP = 6
const PER_PAGE_MOBILE = 8

export function SolutionsMenu({ }: SolutionsMenuProps) {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null)
  const [openMenu, setOpenMenu] = useState(false);
  const [isSelectedAll, setIsSelectedAll] = useState(true)
  const history = useHistory()
  const { t, i18n } = useTranslation();

  // const [page, setPage] = useState(1);

  const [page, setPage] = useState(1);
  // const displayedData = usePagination(USE_CASES, PER_PAGE_DESKTOP);
  const filteredData = selectedCategory ? USE_CASES.filter(useCase => useCase.industries.some(ind => ind.id === selectedCategory)) : USE_CASES;

  const pageCount = Math.ceil(filteredData.length / (PER_PAGE_DESKTOP));
  const displayedData = usePagination(filteredData, PER_PAGE_DESKTOP
  );


  useEffect(() => {
    handlePageChange(1);
  }, [selectedCategory])

  const handlePageChange = async (_page: number) => {
    setPage(_page);
    displayedData.jump(_page);
  };

  const getItemProps = (index: any) =>
  ({
    variant: page === index ? "filled" : "text",
    color: page === index ? "blue" : "blue-gray",
    onClick: () => handlePageChange(index),
  } as any);

  const next = () => {
    if (page === pageCount) return;
    handlePageChange(page + 1);
  };

  const prev = () => {
    if (page === 1) return;
    handlePageChange(page - 1);
  };

  const handleSelectMenu = (id: string) => {
    setIsSelectedAll(false);
    setSelectedCategory(id);
    displayedData.jump(1);
  }


  const handleSelectAll = () => {
    setIsSelectedAll(true);
    setSelectedCategory(null);
    displayedData.jump(1);
  }


  const selectUseCase = (id: string) => {
    history.push(`/solutions/${id}`)
  }

  return (
    <section className="w-full mt-20 lg:mt-24 dark:text-white text-neutral-600 " >
      <motion.h2
        initial={FADE_BOTTOM.initial}
        whileInView={FADE_BOTTOM.final}
        transition={{
          duration: 0.3,
          delay: 0.3
        }}
        viewport={{ once: true }}
        className="text-2xl sm:text-3xl md:text-4xl font-bold tracking-tight text-center ">Computer Vision Solutions
      </motion.h2>

      <motion.h3
        initial={FADE_BOTTOM.initial}
        whileInView={FADE_BOTTOM.final}
        transition={{
          duration: 0.3,
          delay: 0.3
        }}
        viewport={{ once: true }}
        className="mt-2 text-lg sm:text-xl font-bold tracking-tight dark:text-gray-400 text-gray-600 text-center">{t("industries_grid_subtitle")}
      </motion.h3>
      <div className="w-full mt-16  relative " >

        <div className="w-full flex justify-center md:hidden" >
          <Menu
            animate={{
              mount: { y: 0 },
              unmount: { y: 25 },
            }}
            open={openMenu} handler={setOpenMenu}>
            <MenuHandler>
              <div className="py-2 px-6 rounded border flex items-center gap-2 " >
                {selectedCategory ?? "Show all"}{" "}
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`h-3.5 w-3.5 transition-transform ${openMenu ? "rotate-180" : ""
                    }`}
                />
              </div>
            </MenuHandler>
            <MenuList>
              {INDUSTRIES.map((item) => (
                <MenuItem>{t(`${item.name}`)}</MenuItem>

              ))}
            </MenuList>
          </Menu>
        </div>


        {/* <Disclosure as="nav">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                <div className="relative flex h-16 items-center justify-between">
                  <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>


                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3">
                  {INDUSTRIES.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      className={classNames(
                        'hover:underline hover:opacity-80 cursor-pointer',
                        'block px-3 py-2 rounded-md text-base font-medium'
                      )}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure> */}

        <main className="flex justify-center w-full" >
          <aside className="z-40 w-80 h-full transition-transform hidden lg:block  select-none" >
            <div className="h-full px-3 py-10 overflow-y-auto  w-full">
              <h3 className="text-lg font-bold text-center" >{t("Categories/Industries")}</h3>

              <ul className="space-y-4 font-medium mt-8">
                <li className="flex items-center justify-center" >
                  <div onClick={handleSelectAll}
                    className={
                      classNames(
                        "w-44 h-12 rounded-2xl inline-flex items-center justify-center cursor-pointer",
                        isSelectedAll ? "bg-white text-black" : "bg-black text-white  hover:bg-neutral-900"
                      )}>
                    {t("Show all")}
                  </div>
                </li>
                {INDUSTRIES.map((i) => (
                  <li className="flex items-center justify-center" >
                    <div onClick={() => handleSelectMenu(i.id)}
                      className={
                        classNames(
                          "w-44 h-12 rounded-2xl inline-flex items-center justify-center cursor-pointer",
                          selectedCategory === i.id ? "bg-white text-black" : "bg-black text-white  hover:bg-neutral-900"
                        )}>
                      {t(`${i.name}`)}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </aside>

          <div className="w-full  max-w-7xl p-10 relative " >
            <div className="min-h-[552px] grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 xl:grid-cols-3 sm:gap-y-4 lg:gap-x-4  items-center justify-center relative z-10">
              {displayedData.currentData().map((i) => (
                <div className="flex items-center justify-center group" >
                  <div onClick={() => selectUseCase(i.id)}
                    className="w-full h-[260px] dark:bg-neutral-900 bg-white p-4 cursor-pointer duration-200 rounded-2xl ">
                    <div className="w-[70px] h-[70px] bg-neutral-200 group-hover:w-[90px] group-hover:h-[90px] duration-200 rounded-xl " >
                      <img className="rounded-xl " src={i.cover} alt="" />
                    </div>
                    <div className="w-full  mt-4" >
                      <p className="text-base font-bold " >
                      {t(`${i.name}`)}
                      </p>
                      <p className="mt-2 text-sm text-gray-500" >
                      {t(`${i.label}`)}
                      </p>
                    </div>
                    <MyButton.Button primary className="mt-4 group-hover:bg-primary-600 duration-200 text-white hover:text-white" >{t("Get started")}</MyButton.Button>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center gap-4 mt-10 w-full justify-center">
              <Button
                variant="text"
                color="blue-gray"
                className="flex items-center gap-2"
                onClick={prev}
                disabled={page === 1}
              >
                <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Prev
              </Button>
              <div className="flex items-center gap-2">
                {Array.from({ length: pageCount }, (_, index) => (
                  <IconButton key={index + 1} {...getItemProps(index + 1)}>{index + 1}</IconButton>
                ))}
              </div>
              <Button
                variant="text"
                color="blue-gray"
                className="flex items-center gap-2"
                onClick={next}
                disabled={page === pageCount}
              >
                Next
                <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </main>
      </div>
    </section>
  );
}

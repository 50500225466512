import React from 'react';
import Footer from './Footer';
import { useEffect, useState } from 'react';
import styles from './styles/home.module.scss';
import { isSafari } from "react-device-detect";
//import { Parallax } from 'react-scroll-parallax';
import json_home_en from './jsons/en/home_en.json';
import json_home_pt from './jsons/pt/home_pt.json';
import { ReactCompareSlider } from 'react-compare-slider';

const Home = (props) => {

    //////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////
    // json data
    const json_data = (props.language === "en" ? json_home_en : json_home_pt);

    // general variables
    const [mouse_inside_slider, set_mouse_inside_slider] = useState(false);
    //const [mouse_click, set_mouse_click] = useState(false);

    const [count, setCount] = useState(0);

    const [position_state, set_position_state] = useState(0);
    const [step, set_step] = useState(1);

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    ///////////////////////////////////////
    // AUXILIARY FUNCTIONS
    ///////////////////////////////////////
    // -----------------------------
    // scroll to the top of the page
    // -----------------------------
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // ------------------------------------
    // redirect the user to the chosen page
    // ------------------------------------
    const handle_click = (link) => {
        window.open(link, "_blank");
    }

    // -------------------------------------------------------------------
    // determine whether the slider should be sliding automatically or not
    // -------------------------------------------------------------------
    const mouse_entered_slider = () => {
        set_mouse_inside_slider(true);
        forceUpdate();
    }
    
    const mouse_left_slider = () => {
        set_mouse_inside_slider(false);
        forceUpdate();
    }

    // ---------------------------------------------------------------------------------------------
    // automatically move the slider
    // ---------------------------------------------------------------------------------------------
    useEffect(() => {
        const timer = setTimeout(() => setCount(count + 1), 1.0);
        
        // determine how fast the slider should move
        let speed = Math.cos(((((position_state) - 50) / 50) / 16) * 24) * 0.5;

        if(!mouse_inside_slider){
            
            if(position_state >= 100)
                set_step(-speed);
                
            else if(position_state <= 0)
                set_step(speed);
            
            set_position_state(position_state + step);
            forceUpdate();
        }
        
        return () => clearTimeout(timer);

        // eslint-disable-next-line
    }, [count]);

    return ( 
        <div className = {styles.desktop_root}>
            <div className = {styles.desktop_inner}>
                <div className = {styles.desktop_inner_video_div}>
                    {/*<video className = {styles.desktop_inner_video} autoPlay loop muted src = {require(`${json_data.desktop.main_background}`)} />*/}
                    <div className = {styles.desktop_image_background}/>
                </div>
                {/*<div className = {styles.curve_div}>

                </div>*/}
                <div className = {styles.text_and_image_container} style = {{marginTop: "calc(var(--navbar_height) * 1.5)", height: "calc(100vh - (var(--navbar_height) * 2))"}}>
                    <div className = {styles.text_container_super}>
                        <div className = {styles.vertical_align}>
                            {/*<Parallax translateY = {[-20, 20]}>*/}
                                <div className = {styles.text_container}>
                                    <div className = {styles.title_div}>
                                        <div className = {styles.vertical_align}>
                                            <div className = {styles.title}>
                                                {json_data.desktop.title}
                                            </div>
                                        </div>
                                    </div>
                                    <div className = {styles.subtitle_div}>
                                        <div className = {styles.vertical_align}>
                                            <div className = {styles.subtitle}>
                                                {json_data.desktop.subtitle}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            {/*</Parallax>*/}
                        </div>
                    </div>
                    
                    <div className = {styles.image_container}>
                        <div className = {styles.vertical_align}>
                            {/*<Parallax translateY = {[-12, 12]}>*/}
                                <div 
                                    className = {styles.main_image}
                                    style = {{backgroundImage: `url(${require(`${json_data.desktop.main_image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}
                                />
                            {/*</Parallax>*/}
                        </div>
                    </div>
                </div>

                <div className = {styles.fade_div_bottom} />
            </div>
            <div className = {styles.partners_div}>
                <div className = {styles.fade_div_bottom} style = {{height: "100px", top: "0", zIndex: "22", backgroundImage: "linear-gradient(to top, rgba(255,0,0,0), rgba(0,0,0,1))"}}/>
                <div className = {styles.partners_div_inner}>
                    <div className = {styles.partner_div}>
                        <div className = {styles.vertical_align} style = {{height: "100%"}}>
                            <div 
                                className = {styles.partner_div_image} 
                                onClick = {() => handle_click(json_data.desktop.partners[0].link)}
                                style = {{backgroundImage: `url(${require(`${json_data.desktop.partners[0].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                            </div>
                        </div>
                    </div>
                    <div className = {styles.partner_div}>
                        <div className = {styles.vertical_align} style = {{height: "100%"}}>
                            <div 
                                className = {styles.partner_div_image} 
                                onClick = {() => handle_click(json_data.desktop.partners[1].link)}
                                style = {{backgroundImage: `url(${require(`${json_data.desktop.partners[1].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                            </div>
                        </div>
                    </div>
                    <div className = {styles.partner_div}>
                        <div className = {styles.vertical_align} style = {{height: "100%"}}>
                            <div 
                                className = {styles.partner_div_image} 
                                onClick = {() => handle_click(json_data.desktop.partners[2].link)}
                                style = {{backgroundImage: `url(${require(`${json_data.desktop.partners[2].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = {styles.what_div} id = "what">
                <div className = {styles.what_div_inner}> 
                    <div className = {styles.project_features_inner_div}>
                        <div className = {styles.left_feature_content}>
                            <div className = {styles.vertical_align_feature}>
                                <div className = {styles.feature_text_div}>
                                    <div className = {styles.top_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.what.suptitle}} />
                                    <div className = {styles.primary_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.what.title}} />
                                    <div className = {styles.secondary_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.what.description}} />
                                </div>
                            </div>
                        </div>
                        <div className = {styles.right_feature_content}>
                            <div className = {styles.vertical_align_feature_image_div}>
                                <div className = {styles.feature_image_div}>
                                    <div className = {styles.vertical_align_feature_image} style = {{width: "50vw"}}>
                                        {isSafari ?
                                            <ReactCompareSlider
                                                className = {styles.slider_safari}
                                                position = {position_state}
                                                changePositionOnHover = {true}
                                                onTouchStart = {() => mouse_entered_slider()}
                                                onMouseEnter = {() => mouse_entered_slider()}
                                                onMouseLeave = {() => mouse_left_slider()}
                                                itemOne = {
                                                        <div className = {styles.slider_video_div}>
                                                            <video className = {styles.slider_video} autoPlay playsInline loop muted src = {require(`${json_data.desktop.what.slider_video_1}`)} />
                                                        </div>
                                                    }
                                                itemTwo = {
                                                        <div className = {styles.slider_video_div}>
                                                            <video className = {styles.slider_video} autoPlay playsInline loop muted src = {require(`${json_data.desktop.what.slider_video_2}`)} />
                                                        </div>
                                                    }
                                            />
                                            : 
                                            <ReactCompareSlider
                                                className = {styles.slider}
                                                position = {position_state}
                                                changePositionOnHover = {true}
                                                onTouchStart = {() => mouse_entered_slider()}
                                                onMouseEnter = {() => mouse_entered_slider()}
                                                onMouseLeave = {() => mouse_left_slider()}
                                                itemOne = {
                                                        <div className = {styles.slider_video_div}>
                                                            <video className = {styles.slider_video} autoPlay playsInline loop muted src = {require(`${json_data.desktop.what.slider_video_1}`)} />
                                                        </div>
                                                    }
                                                itemTwo = {
                                                        <div className = {styles.slider_video_div}>
                                                            <video className = {styles.slider_video} autoPlay playsInline loop muted src = {require(`${json_data.desktop.what.slider_video_2}`)} />
                                                        </div>
                                                    }
                                            />}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = {styles.company_description_div}>
                <div className = {styles.fade_div_bottom} style = {{height: "50%", top: "0", zIndex: "22", backgroundImage: "linear-gradient(to top, rgba(255,0,0,0), rgba(0,0,0,1))"}}/>
                {/*<div className = {styles.company_description_background} style = {{backgroundImage: `url(${require(`${json_data.desktop.company_description.background_image}`)})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}} />*/}
                <div className = {styles.company_description_background} style = {{backgroundImage: `url(${require(`${json_data.desktop.company_description.background_image}`)})`}} />
                
                    <div className = {styles.company_description_div_inner}> 
                        <div className = {styles.description_item_div}>
                            {/*<Parallax translateY = {[-12, 12]}>*/}
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                    <div className = {styles.description_item_image_div}>
                                        <div className = {styles.description_item_image}
                                            style = {{backgroundImage: `url(${require(`${json_data.desktop.company_description.items[0].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                                        </div>
                                    </div>
                                    <div className = {styles.description_item_title_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[0].title}} />
                                    <div className = {styles.description_item_description_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[0].description}} />
                                </div>
                            {/*</Parallax>*/}
                        </div>
                        <div className = {styles.description_item_div}>
                            {/*<Parallax translateY = {[-12, 12]}>*/}
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                    <div className = {styles.description_item_image_div}>
                                        <div className = {styles.description_item_image}
                                            style = {{backgroundImage: `url(${require(`${json_data.desktop.company_description.items[1].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                                        </div>
                                    </div>
                                    <div className = {styles.description_item_title_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[1].title}} />
                                    <div className = {styles.description_item_description_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[1].description}} />
                                </div>
                            {/*</Parallax>*/}
                        </div>
                        <div className = {styles.description_item_div}>
                            {/*<Parallax translateY = {[-12, 12]}>*/}
                                <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                    <div className = {styles.description_item_image_div}>
                                        <div className = {styles.description_item_image}
                                            style = {{backgroundImage: `url(${require(`${json_data.desktop.company_description.items[2].image}`)})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "contain"}}>
                                        </div>
                                    </div>
                                    <div className = {styles.description_item_title_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[2].title}} />
                                    <div className = {styles.description_item_description_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.company_description.items[2].description}} />
                                </div>
                            {/*</Parallax>*/}
                        </div>
                    </div>
                <div className = {styles.fade_div_bottom} style = {{height: "50%", bottom: "0", zIndex: "22", backgroundImage: "linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1))"}}/>
            </div>
        
            <div className = {styles.awards_news_div} id = "awards_and_news">
                <div className = {styles.awards_news_div_inner}> 
                    <div className = {styles.awards_news_content_div}> 
                        <div className = {styles.awards_content_div}>
                            <div className = {styles.vertical_align_feature}>
                                <div className = {styles.awards_news_text_div}>
                                    <div className = {styles.awards_news_text_div_inner}>
                                        <div className = {styles.top_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.awards_news.suptitle}} />
                                        <div className = {styles.primary_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.awards_news.title}} />
                                        <div className = {styles.secondary_text_div} dangerouslySetInnerHTML = {{__html: json_data.desktop.awards_news.description}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className = {styles.awards_news_grid}>
                            <div className = {styles.vertical_align} style = {{height: "100%"}}>
                                <div className = {styles.awards_news_grid_inner}>
                                    {json_data.desktop.awards_news.blocks.map((block) => (
                                        <div className = {styles.awards_news_block} key = {json_data.desktop.awards_news.blocks.indexOf(block)}>
                                            <div className = {styles.awards_news_block_image} onClick = {() => handle_click(block.link)} style = {{backgroundImage: `url(${require(`${block.image}`)})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}} />
                                            <div className = {styles.awards_news_block_text}>
                                                {block.description}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className = {styles.office_background_div}>
                {/*<div className = {styles.fade_div_bottom} style = {{height: "25%", top: "0", zIndex: "22", backgroundImage: "linear-gradient(to top, rgba(243,243,243,0), rgba(0,0,0,1))"}}/>*/}
                <div className = {styles.office_background} style = {{backgroundImage: `url(${require(`${json_data.desktop.office.background_image}`)})`}} />
                <div className = {styles.office_background_text_div}>
                    <div className = {styles.vertical_align} style = {{height: "fit-content"}}>
                        {/*<Parallax translateY = {[-20, 20]}>*/}
                            <div className = {styles.office_background_text_div_inner}>
                                <div className = {styles.team_div_text}>{json_data.desktop.office.suptitle}</div>
                                <div className = {styles.team_div_description}>{json_data.desktop.office.title}</div>
                            </div>
                        {/*</Parallax>*/}
                    </div>
                </div>
                <div className = {styles.fade_div_bottom} style = {{height: "50%", bottom: "0", zIndex: "22", backgroundImage: "linear-gradient(to bottom, rgba(255,0,0,0), rgba(243,243,243,1))"}}/>
            </div>

            <div className = {styles.team_div} id = "team">
                <div className = {styles.fade_div_bottom} style = {{bottom: "0", height: "75%", zIndex: "22", backgroundImage: "linear-gradient(to top, rgba(243,243,243,0.0), rgba(243,243,243,1))"}}/>
                <div className = {styles.team_div_background} style = {{backgroundImage: `url(${require(`${json_data.desktop.team.background_image}`)})`}} />
                <div className = {styles.team_div_inner}>

                    {json_data.desktop.team.groups.map((group) => (
                        <div className = {styles.team_group_div} key = {json_data.desktop.team.groups.indexOf(group)}>
                            <div className = {styles.team_group_name} dangerouslySetInnerHTML = {{__html: group.name}} />
                            <div className = {styles.team_cards_div}>
                                <div className = {styles.team_cards_div_inner}>
                                    {group.members.map((team_member) => (
                                        <div className = {styles.team_card} key = {group.members.indexOf(team_member)}>
                                            <div className = {styles.team_card_inner} onClick = {() => handle_click(team_member.link)}>
                                                <div className = {styles.team_card_top_bar}/>
                                                <div className = {styles.team_card_content}>
                                                    <div className = {styles.team_card_image} style = {{backgroundImage: `url(${require(`${team_member.image}`)})`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center"}} />
                                                    <div className = {styles.team_card_icon_div}>
                                                        <div className = {styles.vertical_align} style = {{height: "fit-content", width: "fit-content", padding: "0"}}>
                                                            <div className = {styles.team_card_icon} style = {{backgroundImage: `url(${require(`${team_member.icon}`)})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center"}} />
                                                        </div>
                                                    </div>
                                                    <div className = {styles.team_card_name_role_div}>
                                                        <div className = {styles.team_card_name} dangerouslySetInnerHTML = {{__html: team_member.name}} />
                                                        <div className = {styles.team_card_role} dangerouslySetInnerHTML = {{__html: team_member.role}} />
                                                    </div>
                                                    {/*<div className = {styles.team_card_description} dangerouslySetInnerHTML = {{__html: team_member.description}} />*/}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
                <div className = {styles.fade_div_bottom} style = {{height: "200px", zIndex: "22", backgroundImage: "linear-gradient(to bottom, rgba(255,0,0,0), rgba(243,243,243,1))"}}/>
            </div>
            
            <Footer language = {props.language}/>
        </div>
    );
}

export default Home;
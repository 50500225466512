import { ReactNode, useEffect, useState } from 'react';
import { ContactUsSection, CtaCard, IndustryVideoSection, Navbar, IndustryGrid, AwardsSection } from '../components';
import { useScroll } from 'framer-motion';
import { IndustryHero } from '../components/IndustryHero';
import ContactForm from '../components/ContactForm';
import { motion } from 'framer-motion'
import { FADE_BOTTOM } from '../animations';
import { CheckIcon } from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';

interface ContactUsProps {
}

export function ContactUs({ }: ContactUsProps) {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()
  const { pathname } = useLocation();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  
  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })

  }, [])

  return (
    <div className="dark:bg-background-900 bg-[#eef3fc]  min-h-screen w-full font-roboto overflow-hidden" >
      <Navbar transparent={navbarTransparent} />
      <div className="isolate dark:bg-transparent bg-primary-500 h-[calc(100vh)]  flex  relative w-full">
        <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]">
          <svg
            className="relative left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
            viewBox="0 0 1155 678"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
              fillOpacity=".3"
              d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
            />
            <defs>
              <linearGradient
                id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
                x1="1155.49"
                x2="-78.208"
                y1=".177"
                y2="474.645"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#42a5f5" />
                <stop offset={1} stopColor="#3849ab" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* Overlay */}
        <div className="h-[calc(100vh)] w-screen absolute opacity-75 bg-gradient-to-r from-transparent to-primary-700" >

          {/* <img className="w-full h-[900px]" src={HeroBg} alt="bg" /> */}
          <div className="h-[900px] w-screen absolute  z-10 bottom-0 opacity-50 bg-black" />
          {/* <div className="h-[900px] w-screen absolute  z-10 bottom-0 opacity-75 bg-gradient-to-r from-transparent to-primary-700"/> */}
          <div className="h-[900px] w-screen absolute  bg-gradient-to-b z-10 bottom-0 from-black via-black/50  to-black" />

        </div>

        {/* Form Content */}
        <main className="w-full flex flex-col justify-center mt-16 sm:mt-24 md:mt-28 mx-auto max-w-7xl" >
          <div className="relative px-6 lg:px-8 max-w-7xl ">
            <div className="mx-auto pt-10 pb-14 sm:pt-20 sm:pb-12 ">

              <div className="text-center p-4" >
                <h1 className="text-xl  sm:text-2xl  md:text-3xl lg:text-4xl font-bold tracking-tight text-white">
                  {t("contact_us")}
                </h1>
                {/* <p className="mt-2 text-2xl sm:text-3xl leading-8 text-gray-500 ">
                Elevating Industry Standards with Our AI-Powered Solutions.
                </p> */}
                <motion.h3
                  initial={FADE_BOTTOM.initial}
                  whileInView={FADE_BOTTOM.final}
                  transition={{
                    duration: 0.3,
                    delay: 0.3
                  }}
                  viewport={{ once: true }}
                  className="mt-2 text-lg sm:text-xl md:text-2xl lg:text-2xl font-bold  text-gray-400 ">{t("We’d love to work with you!")}
                </motion.h3>
                <div className="w-full flex mt-6 items-center justify-center" >
                  {/* <Cta >Try now</Cta> */}
                </div>

              </div>

              {/* Pattern */}
              <div className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
                <svg
                  className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                  viewBox="0 0 1155 678"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                    fillOpacity=".3"
                    d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                  />
                  <defs>
                    <linearGradient
                      id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                      x1="1155.49"
                      x2="-78.208"
                      y1=".177"
                      y2="474.645"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#3849ab" />
                      <stop offset={1} stopColor="#42a5f5" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>

            </div>
          </div>

          <div className=" w-full mx-auto max-w-7xl relative z-20 flex gap-10" >
            <ContactForm />

            <div className=" h-96 lg:h-80 dark:bg-neutral-900 bg-primary-700 rounded-2xl p-10 hidden md:block " >
              <div>
                <motion.h3
                  initial={FADE_BOTTOM.initial}
                  whileInView={FADE_BOTTOM.final}
                  transition={{
                    duration: 0.3,
                    delay: 0.3
                  }}
                  viewport={{ once: true }}
                  className="mt-2 text-lg lg:text-xl xl:text-2xl font-bold tracking-tight text-gray-100 ">{t("contact_advantage_title")}
                </motion.h3>

                <motion.ul
                  initial={FADE_BOTTOM.initial}
                  whileInView={FADE_BOTTOM.final}
                  transition={{
                    duration: 0.3,
                    delay: 0.3
                  }}
                  viewport={{ once: true }}
                  className="mt-3 text-base lg:text-lg xl:text-xl  dark:text-gray-400 text-gray-200 ">
                  <li className="flex items-center mt-2" >
                    <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                    {t("contact_advantage1")}
                  </li>
                  <li className="flex items-center  mt-2" >
                    <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                    {t("contact_advantage2")}
                  </li>
                  <li className="flex items-center mt-2" >
                    <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                    {t("contact_advantage3")}
                  </li>
                  <li className="flex items-center mt-2" >
                    <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                    {t("contact_advantage4")}
                  </li>
                </motion.ul>


              </div>
            </div>

          </div>
        </main>

        <div className="absolute bottom-5 px-20 w-full h-16 flex items-center justify-between opacity-75" >
          {/* <img className="h-16" src={UbiMedical} alt="HealthTech" />
        <img className="h-16" src={HealthTech} alt="HealthTech" />
        <img className="h-16" src={Ubi} alt="HealthTech" /> */}
        </div>
      </div>
      <AwardsSection />
      <div className="flex h-24 w-full items-center justify-center border-t mt-28">

      </div>
      <Footer/>

    </div>
  );
}

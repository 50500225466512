import { ReactNode } from 'react';
import { motion } from 'framer-motion'
import HeroBg from "../resources/computer_vision.png"
import { CheckIcon } from '@heroicons/react/24/outline'
import HealthTech from "../resources/images/healthtech.png"
import Ubi from "../resources/images/ubi.png"
import UbiMedical from "../resources/images/ubimedical.png"
import { Cta } from './Cta';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ContactUsSectionProps {
}

const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}


export function ContactUsSection({ }: ContactUsSectionProps) {
  const history = useHistory()
  const { t, i18n } = useTranslation();

  const click = () => {
    history.push(`/contact`)

  }

  return (
    <section className="mt-16 lg:mt-24 w-full dark:text-white text-neutral-600 " >
      <div className="w-full mx-auto max-w-7xl" >

        <motion.div
          className="w-full px-4 xl:px-0 flex relative items-center"
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: true }}
        >
          <motion.div
            initial={{
              height: 0
            }}
            whileInView={{
              height: 96
            }}
            transition={{
              duration: .6,
              delay: 0.1
            }}
            viewport={{ once: true }}
            className="bg-gradient-to-b from-primary-600 to-cyan-500  relative w-1 h-24"
          />

          <div className="ml-4" >
            <motion.h2
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.3
              }}
              viewport={{ once: true }}
              className="text-xl md:text-3xl lg:text-4xl font-bold tracking-tight" >{t("contact_us")}
            </motion.h2>

            <motion.h3
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.5
              }}
              viewport={{ once: true }}
              className="mt-2 text-base lg:text-xl font-bold tracking-tight dark:text-gray-400 text-gray-600 underline underline-offset-8 ">{t("solutions_slogan")}
            </motion.h3>

          </div>

        </motion.div>

      </div>

      <div className="mt-16 w-full sm:h-[350px] md:h-[500px] bg-neutral-500 border-t-2 border-white relative overflow-hidden" >

        <img className="w-full absolute" src={HeroBg} alt="bg" />
        <div className=" w-screen absolute bottom-0 top-0 z-10  bg-gradient-to-r from-primary-500/90   dark:from-black dark:via-primary-600/60  dark:to-black to-primary-500/20" />
        <div className=" w-screen absolute  z-10 bottom-0 top-0 opacity-50 bg-black" />


        <div className="z-20 relative w-full mx-auto max-w-7xl h-full flex flex-col md:flex-row items-center justify-between p-10 sm:p-10 md:p-2" >
          <div>
            <motion.h3
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.3
              }}
              viewport={{ once: true }}
              className="mt-2 text-lg sm:text-xl md:text-2xl font-bold tracking-tight text-gray-100 ">{t("contact_advantage_title")}
            </motion.h3>

            <motion.ul
              initial={FADE_BOTTOM.initial}
              whileInView={FADE_BOTTOM.final}
              transition={{
                duration: 0.3,
                delay: 0.3
              }}
              viewport={{ once: true }}
              className="mt-2 md:mb-0 mb-4 text-base sm:text-lg md:text-xl font-bold tracking-tight text-gray-400 ">
              <li className="flex items-center" >
                <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                {t("contact_advantage1")}
              </li>
              <li className="flex items-center" >
                <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                {t("contact_advantage2")}
              </li>
              <li className="flex items-center" >
                <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                {t("contact_advantage3")}
              </li>
              <li className="flex items-center" >
                <CheckIcon className="w-6 h-6 text-green-500 mr-1" />
                {t("contact_advantage4")}
              </li>
            </motion.ul>


          </div>

          <Cta onClick={click} >
            {t("Talk to sales")}
          </Cta>

        </div>
      </div>

      {/* <div className="mt-20 bottom-5 md:px-20 w-full mx-auto max-w-7xl  opacity-75" >
        <h2 className="w-full text-white text-center text-lg" >Trusted by:</h2>
        <div className="mx-auto mt-4 max-w-7xl px-2 sm:px-6 lg:px-8 flex items-center justify-center gap-4 sm:gap-6 md:gap-16 w-full" >
          <img className="h-8 sm:h-10 md:h-16" src={UbiMedical} alt="HealthTech" />
          <img className="h-8 sm:h-10 md:h-16" src={HealthTech} alt="HealthTech" />
          <img className="h-8 sm:h-10 md:h-16" src={Ubi} alt="HealthTech" />
        </div>
      </div> */}

    </section>
  );
}

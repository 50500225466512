/**
 * Wrapper component for managing and sharing the menu state
 * @options - stores all elements properties
 * @targetId - stores the currently selected element
 * @cachedId - stores previously selected element
 * @registerOption - registers elements on @options
 */
import React, { useCallback, useEffect, useState } from "react";
//-----------------------------------------------------------------------------

export const Context = React.createContext({} as any);

//TODO update types and doc
type Option = {
  id: number;
  optionDimensions: any;
  optionCenterX: any;
  WrappedContent: any;
  backgroundHeight: any;
  offset?: number
} 
//-----------------------------------------------------------------------------

export function DropdownProvider({ children }:any) {
  const [options, setOptions] = useState<Option[]>([]);
  const [targetId, setTargetId] = useState(null);
  const [cachedId, setCachedId] = useState(null);

  const registerOption = useCallback(
    ({
      //@ts-ignore
      id,
      //@ts-ignore
      optionDimensions,
      //@ts-ignore
      optionCenterX,
      //@ts-ignore
      WrappedContent,
      //@ts-ignore
      backgroundHeight,
      //@ts-ignore
      offset
    }) => {
      setOptions((items) => [
        ...items,
        {
          id,
          optionDimensions,
          optionCenterX,
          WrappedContent,
          backgroundHeight,
          offset
        },
      ]);
    },
    [setOptions]
  );
  const updateOptionProps = useCallback(
    (optionId:string | number, props:any) => {
      setOptions((items) =>
        items.map((item) => {
          if (item.id === optionId) {
            item = { ...item, ...props };
          }

          return item;
        })
      );
    },
    [setOptions]
  );

  const deleteOptionById = useCallback(
    (id:string | number) => {
      setOptions((items) => items.filter((item) => item.id !== id));
    },
    [setOptions]
  );

  const getOptionById = useCallback(
    (id:string | number) => options.find((item) => item.id === id),
    [options]
  );

  useEffect(() => {
    if (targetId !== null) {
      setCachedId(targetId);
    }
  }, [targetId]);

  return (
    <Context.Provider
      value={{
        registerOption,
        updateOptionProps,
        getOptionById,
        deleteOptionById,
        options,
        targetId,
        setTargetId,
        cachedId,
        setCachedId,
      }}
    >
      {children}
    </Context.Provider>
  );
}
import { ReactNode, useState } from 'react';
import { motion, AnimatePresence } from "framer-motion";
//@ts-ignore
import bg from "../assets/images/demo_bg.png"

interface AnimatedBackgroundProps {
}

export function AnimatedBackground({ }: AnimatedBackgroundProps) {
  const [isFirstImage, setIsFirstImage] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const handleAnimationComplete = () => {
    setIsFirstImage(!isFirstImage);
    setIsFirstLoad(false)
  };

  return (
    <>
      <div className="absolute inset-x-0  z-0 transform-gpu overflow-hidden blur-3xl top-[10rem]">
        <svg
          className="relative animate-move left-[calc(50%-11rem)] -z-10 h-[21.1875rem] max-w-none -translate-x-1/2 rotate-[30deg] sm:left-[calc(50%-30rem)] sm:h-[42.375rem]"
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
          className=''
            fill="url(#45de2b6b-92d5-4d68-a6a0-9b9b2abad533)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          <defs>
            <linearGradient
              id="45de2b6b-92d5-4d68-a6a0-9b9b2abad533"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#262626" />
              <stop offset={1} stopColor="#3b82f6" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div className=" absolute inset-x-0 top-[15rem] z-0 transform-gpu overflow-hidden blur-3xl ">
        <svg 
        id="sw-js-blob-svg" 
        className="relative left-[10rem] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[10rem]  sm:h-[42.375rem]"
        viewBox="0 0 100 100" 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1">
        <defs>
          <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
            <stop id="stop1" stopColor="#3b82f6" offset="0%"></stop>
            <stop id="stop2" stopColor="#3b82f6" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path 
        
        fill="url(#sw-gradient)" 
        fillOpacity={".2"} d="M19.7,-31.6C26.2,-30.4,32.5,-26.4,35,-20.7C37.5,-14.9,36.1,-7.5,33.5,-1.5C30.8,4.4,26.9,8.8,23.5,13C20.1,17.2,17.3,21.1,13.5,25.7C9.6,30.2,4.8,35.4,-1.1,37.3C-6.9,39.1,-13.9,37.6,-20,34.3C-26.1,31.1,-31.4,26.1,-35.1,20.1C-38.8,14.1,-40.9,7.1,-38.8,1.2C-36.6,-4.6,-30.3,-9.2,-25.2,-12.9C-20.2,-16.5,-16.4,-19.3,-12.4,-21.9C-8.5,-24.5,-4.2,-27,1.2,-29.1C6.6,-31.2,13.3,-32.8,19.7,-31.6Z" width="100%" height="100%" transform="translate(50 50)" strokeWidth="0"></path>
      </svg>
      </div>

      {/* <AnimatePresence>
        {isFirstImage ? (
          <motion.div
            key="first"
            className="w-[120%] h-[600px]"
            style={{
              backgroundImage: ` url(${bg})`,
              backgroundSize: "100%",
              backgroundRepeat: "repeat-x",
            }}
            initial={{ opacity: 0, x: 0 }}
            animate={{
              x: -100,
              opacity: 1
            }}
            transition={{
              delay: isFirstLoad ? 0.5 : 1,
              opacity: {
                duration: isFirstLoad ? 2 : 3,
                ease: "linear",
              },
              x: {
                duration: 7,
                ease: "linear",
              }
            }}
            onAnimationComplete={handleAnimationComplete}
            exit={{
              opacity: 0, transition: {
                duration: 0.5
              }
            }}
          />
        ) : (
          <motion.div
            key="second"
            className="w-[120%] h-[600px] "

            style={{
              backgroundImage: ` url(${bg})`,
              backgroundSize: "100%",
              backgroundRepeat: "repeat-x",
            }}

            initial={{ opacity: 0, x: -100, }}
            animate={{
              x: 0,
              opacity: 1
            }}
            transition={{
              delay: 1,
              opacity: {
                duration: 3,
                ease: "linear",
              },
              x: {
                duration: 7,
                ease: "linear",
              }
            }}
            exit={{
              opacity: 0, transition: {
                duration: 0.5
              }
            }}
            onAnimationComplete={handleAnimationComplete}
          />
        )}
      </AnimatePresence> */}

      <div className="absolute inset-x-0 top-[calc(100%-40rem)] z-0 transform-gpu overflow-hidden blur-3xl ">
        <svg
          className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem] "
          viewBox="0 0 1155 678"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
          className='animate-spin-slow'
            fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
            fillOpacity=".3"
            d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
          />
          
          <defs>
            <linearGradient
              id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
              x1="1155.49"
              x2="-78.208"
              y1=".177"
              y2="474.645"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3b82f6" />
              <stop offset={1} stopColor="#A4DBF9" />
            </linearGradient>
          </defs>
        </svg>

      </div>

      <div className=" absolute inset-x-0 top-[calc(100%-30rem)] z-0 transform-gpu overflow-hidden blur-3xl ">
        <svg 
        id="sw-js-blob-svg" 
        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
        viewBox="0 0 100 100" 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1">
        <defs>
          <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
            <stop id="stop1" stopColor="#3b82f6" offset="0%"></stop>
            <stop id="stop2" stopColor="#06b6d4" offset="100%"></stop>
          </linearGradient>
        </defs>
        <path 
        
        fill="url(#sw-gradient)" 
        fillOpacity={".2"} d="M19.7,-31.6C26.2,-30.4,32.5,-26.4,35,-20.7C37.5,-14.9,36.1,-7.5,33.5,-1.5C30.8,4.4,26.9,8.8,23.5,13C20.1,17.2,17.3,21.1,13.5,25.7C9.6,30.2,4.8,35.4,-1.1,37.3C-6.9,39.1,-13.9,37.6,-20,34.3C-26.1,31.1,-31.4,26.1,-35.1,20.1C-38.8,14.1,-40.9,7.1,-38.8,1.2C-36.6,-4.6,-30.3,-9.2,-25.2,-12.9C-20.2,-16.5,-16.4,-19.3,-12.4,-21.9C-8.5,-24.5,-4.2,-27,1.2,-29.1C6.6,-31.2,13.3,-32.8,19.7,-31.6Z" width="100%" height="100%" transform="translate(50 50)" strokeWidth="0"></path>
      </svg>
      </div>
    </>
  );
};

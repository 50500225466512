
// Icons
import Building from "../resources/icons/menu_icons/building_icon_1.svg"
import SmartCity from "../resources/icons/menu_icons/smart_city.svg"
import Careers from "../resources/icons/menu_icons/careers_1.svg"
import Company from "../resources/icons/menu_icons/company.svg"
import Contact from "../resources/icons/menu_icons/contact_1.svg"
import Crowd from "../resources/icons/menu_icons/crowd_icon_2-1.svg"
import Education from "../resources/icons/menu_icons/education_1.svg"
import Healthcare from "../resources/icons/menu_icons/healthcare_icon.svg"
import Home from "../resources/icons/menu_icons/home_2.svg"
import Industries from "../resources/icons/menu_icons/industries.svg"
import ItemIcon from "../resources/icons/menu_icons/item.svg"
import Legal from "../resources/icons/menu_icons/legal.svg"
import Partners from "../resources/icons/menu_icons/partners.svg"
import Storefront from "../resources/icons/menu_icons/storefront_1.svg"
import Transportation from "../resources/icons/menu_icons/transport.svg"
import retailIcon from "../resources/icons/menu_icons/retail.svg"
//images
import traffic from "../resources/images/industries/traffic.png"
import classify from "../resources/images/industries/classify.png"
import containers from "../resources/images/industries/containers.png"
import animalDetection from "../resources/images/industries/animalDetection.png"
import wrongWay from "../resources/images/industries/wrong_way.png"
import people from "../resources/images/industries/people.png"
import weapon from "../resources/images/industries/weapon.png"
import abandoned_object from "../resources/images/industries/abandoned_object.png"
import retail from "../resources/images/industries/retail.png"
import parking from "../resources/images/industries/parking.png"
import intrusion from "../resources/images/industries/intrusion.png"
import road_accident from "../resources/images/industries/road_accident.png"
import houseFire from "../resources/images/industries/houseFire.png"

// predictions Icons
import CarIcon from "./icons/predictions/car.svg"
import BusIcon from "./icons/predictions/bus.svg"
import TruckIcon from "./icons/predictions/truck.svg"
import TrainIcon from "./icons/predictions/train.svg"
import Motorcycle from "./icons/predictions/motorcycle.svg"
import Bicycle from "./icons/predictions/bicycle.svg"

import Backpack from "./icons/predictions/backpack.svg"
import Handbag from "./icons/predictions/handbag.svg"
import AbandonedObjectIcon from "./icons/predictions/abandoned_object.svg"
import Suitcase from "./icons/predictions/suitcase.svg"

import Fire from "./icons/predictions/fire.svg"
import Smoke from "./icons/predictions/smoke.svg"
import FireRaising from "./icons/predictions/fire_raising.svg"

import PersonIcon from "./icons/predictions/person.svg"
import StandingUp from "./icons/predictions/standing_up.svg"
import Climbing from "./icons/predictions/climbing.svg"
import SittingDown from "./icons/predictions/sitting_down.svg"
import Riding from "./icons/predictions/riding.svg"
import Lying from "./icons/predictions/lying.svg"
import Throwing from "./icons/predictions/throwing.svg"
import Falling from "./icons/predictions/falling.svg"
import Standing from "./icons/predictions/standing.svg"
import Jumping from "./icons/predictions/jumping.svg"
import Walking from "./icons/predictions/walking.svg"
import Running from "./icons/predictions/running.svg"

import Graffiti from "./icons/predictions/graffiti.svg"
import Fighting from "./icons/predictions/fighting.svg"

//use cases images
import animals1 from "../resources/images/industries/animals1.png"
import animals2 from "../resources/images/industries/animals2.png"

import parkingLot from "../resources/images/industries/parking.gif"
import parkingLot1 from "../resources/images/industries/parking2.gif"
import parkingLot2 from "../resources/images/industries/parking_lot2.png"
import intrusion1 from "../resources/images/industries/intrusion1.png"
import intrusion2 from "../resources/images/industries/intrusion2.png"
import intrusion3 from "../resources/images/industries/intrusion3.png"
import classify1 from "../resources/images/industries/classify1.png"
import crowd1 from "../resources/images/industries/crowd.gif"
import crowd2 from "../resources/images/industries/crowd1.png"
import crowd3 from "../resources/images/industries/crowd2.gif"
import wrongWay2 from "../resources/images/industries/wrong_way2.png"
import weapon1 from "../resources/images/industries/weapon1.gif"
import weapon2 from "../resources/images/industries/weapon2.gif"
import weapon3 from "../resources/images/industries/weapon2.png"
import queue1 from "../resources/images/industries/queue1.png"
import queue2 from "../resources/images/industries/queue2.png"
import stealing from "../resources/images/industries/stealing.png"
import stealing1 from "../resources/images/industries/stealing1.gif"
import vandalism1 from "../resources/images/industries/vandalism1.gif"
import abandoned_object1 from "../resources/images/industries/abandoned_object1.gif"
import abandoned_object2 from "../resources/images/industries/abandoned_object2.gif"
import abandoned_object3 from "../resources/images/industries/abandoned_object1.png"
import crash1 from "../resources/images/industries/crash1.gif"
import crash2 from "../resources/images/industries/crash2.gif"
import fire1 from "../resources/images/industries/fire1.gif"
import fire2 from "../resources/images/industries/fire2.gif"
import fire3 from "../resources/images/industries/fire3.gif"
import heatmap1 from "../resources/images/industries/heatmap1.gif"
import heatmap2 from "../resources/images/industries/heatmap2.gif"
//@ts-ignore
import healthcare from "../resources/images/industries/healthcare.png"
import heatmap from "../resources/images/industries/heatmap.png"

//industries videos
import fireDetection from "../resources/videos/industries/fireDetection.mp4"
import heatmapVideo from "../resources/videos/industries/heatmap.mp4"
import abandoned_object_video from "../resources/videos/industries/abandoned_objects.mp4"
import stealing_video from "../resources/videos/industries/stealing.mp4"
import crash_video from "../resources/videos/industries/crash.mp4"
import crowd1_video from "../resources/videos/industries/crowd1.mp4"
import home_anomalies from "../resources/videos/industries/home_anomalies.mp4"
import vandalizing from "../resources/videos/industries/vandalizing.mp4"

//partners & clients
import bosh from "./images/partners_clients/Bosh.png"
import CHCBeira_univ from "./images/partners_clients/CHCBeira_univ.png"
import CMA_Novo from "./images/partners_clients/CMA_Novo.png"
import Globalvia from "./images/partners_clients/Globalvia.png"
import Ascendi from "./images/partners_clients/Logo_Ascendi.png"
import Nos from "./images/partners_clients/Nos.png"
import Thales_Logo from "./images/partners_clients/Thales_Logo.png"
import avigilon from "./images/partners_clients/avigilon.png"
import ibm_softinsa from "./images/partners_clients/ibm_softinsa.png"
import milestone from "./images/partners_clients/milestone-systems.png"
import prosegur from "./images/partners_clients/prosegur.png"
import proav from "./images/partners_clients/LOGO-PROAV.png"



//TODO add vandalism and graffiti as use case and update menu link

export const ICONS = {
  building: Building,
  careers: Careers,
  company: Company,
  contact: Contact,
  crowd: Crowd,
  education: Education,
  healthcare: Healthcare,
  home: Home,
  industries: Industries,
  item: ItemIcon,
  legal: Legal,
  partners: Partners,
  storefront: retailIcon,
  transportation: Transportation,
}
//------------------------------------------------------------------------------

// Menu items
// export const SOLUTIONS_MENU = {
//   title: 'Solutions',
//   items: {
//     first_column: [
//       { id:"smart_city", name: "Smart city", link: "/industries/smart_city", icon: SmartCity },
//       { id:"house", name: "House and domestic", link: "/industries/domestic", icon: Home },
//       { id:"transportation", name: "Transportation", link: "/industries/transportation", icon: Transportation },
//       // { id:"education", name: "Education", link: "/industries/education", icon: Education },
//       { id:"retail", name: "Retail", link: "/industries/retail", icon: Storefront },
//       // { id:"health", name: "Health", link: "/industries/health", icon: Healthcare },
//     ],
//     second_column: [
//       { name: "Weapon detection", link: "/solutions/weapon_detection", icon: ItemIcon, industries:["smart_city", "retail"] },
//       { name: "Abandoned objects", link: "/solutions/abandoned_luggage", icon: ItemIcon , industries:["retail", "smart_city", "transportation", "government", "security"] },
//       { name: "Queue detection and people counter", link: "/solutions/crowd_management", icon: ItemIcon , industries:["transportation", "smart_city"] },
//       { name: "Intrusions and disallowed areas", link: "/solutions/intrusion", icon: ItemIcon , industries:["smart_city", "retail", "house", "government", "security"] },
//       { name: "Vandalism, graffiti, damaging detection", link:"/solutions/anomalies", icon: ItemIcon , industries:["smart_city", "government", "security"] },
//       { name: "Movement heat maps", link: "/solutions/movement_heatmaps", icon: ItemIcon , industries:["retail", "government", "security"] },
//       { name: "Crash detection", link: "/solutions/crash_detection", icon: ItemIcon , industries:["transportation", "government", "security"] },
//     ],
//     third_column: [
//       { name: "Environmental Monitoring", link: "/solutions/env_monitoring_fire_detection", icon: ItemIcon , industries:["smart_city", "government"] },
//       { name: "Parking lot occupancy", link: "/solutions/parking_lot", icon: ItemIcon , industries:["smart_city", "transportation"] },
//       { name: "Classify/filter vehicles", link: "/solutions/classify_vehicle", icon: ItemIcon , industries:["transportation"] },
//       { name: "Wrong-way direction", link: "/solutions/wrong_way_direction", icon: ItemIcon , industries:["transportation"] },
//       { name: "Animals on the road", link: "/solutions/animal_detection", icon: ItemIcon , industries:["transportation", "government"] },
//       { name: "Queue detection", link: "/solutions/queue_detection", icon: ItemIcon , industries:["transportation"] },
//       // { name: "Object Counting", link: "/solutions/object_counting", icon: ItemIcon , industries:["retail", "house"] },
//     ],
//   },
// }

export const SOLUTIONS_MENU = {
  title: 'menu.solutions',
  items: {
    first_column: [
      { id: "smart_city", name: "menu.smart_city", link: "/industries/smart_city", icon: SmartCity },
      { id: "house", name: "menu.house", link: "/industries/domestic", icon: Home },
      { id: "transportation", name: "menu.transportation", link: "/industries/transportation", icon: Transportation },
      { id: "retail", name: "menu.retail", link: "/industries/retail", icon: retailIcon },
    ],
    second_column: [
      { name: "menu.weapon_detection", link: "/solutions/weapon_detection", icon: ItemIcon, industries: ["smart_city", "retail", "house"] },
      { name: "menu.abandoned_objects", link: "/solutions/abandoned_luggage", icon: ItemIcon, industries: ["retail", "smart_city", "transportation", "government", "security"] },
      { name: "menu.queue_detection_and_people_counter", link: "/solutions/crowd_management", icon: ItemIcon, industries: [ "smart_city", "retail"] },
      { name: "menu.intrusions_and_disallowed_areas", link: "/solutions/intrusion", icon: ItemIcon, industries: ["smart_city", "retail", "house", "government", "security"] },
      // { name: "menu.vandalism_graffiti_damaging_detection", link:"/solutions/anomalies", icon: ItemIcon , industries:["smart_city", "government", "security"] },
      { name: "menu.movement_heat_maps", link: "/solutions/movement_heatmaps", icon: ItemIcon, industries: ["retail", "government", "security"] },
      { name: "menu.crash_detection", link: "/solutions/crash_detection", icon: ItemIcon, industries: ["transportation", "government", "security"] },
    ],
    third_column: [
      { name: "menu.environmental_monitoring", link: "/solutions/env_monitoring_fire_detection", icon: ItemIcon, industries: ["smart_city", "government"] },
      { name: "menu.parking_lot_occupancy", link: "/solutions/parking_lot", icon: ItemIcon, industries: ["smart_city", "transportation"] },
      { name: "menu.classify_filter_vehicles", link: "/solutions/classify_vehicle", icon: ItemIcon, industries: ["transportation"] },
      { name: "menu.wrong_way_direction", link: "/solutions/wrong_way_direction", icon: ItemIcon, industries: ["transportation"] },
      { name: "menu.animals_on_the_road", link: "/solutions/animal_detection", icon: ItemIcon, industries: ["transportation", "government"] },
      { name: "menu.queue_detection", link: "/solutions/queue_detection", icon: ItemIcon, industries: ["transportation"] },
    ],
  },
}


export const COMPANY_MENU: CompanyMenu = {
  title: 'company_menu_title',
  items: [
    { name: "company_menu_about", link: "/about", icon: "company" },
    { name: "company_menu_contact", link: "/contact", icon: "contact" },
    { name: "company_menu_partners", link: "/#partners", icon: "partners" },
    // { name: "company_menu_careers", link: "/careers", icon: "careers" },
    // { name: "company_menu_legal", link: "/terms", icon: "legal" },
  ],
}

//------------------------------------------------------------------------------

// Industries && use cases
export const INDUSTRIES = [
  {
    id: "transportation",
    name: 'industries_transportation_name',
    aspects: ['Road', 'Subway', 'Train', 'Harbour and airport'],
    label: "industries_transportation_label",
    title: "industries_transportation_title",
    subtitle: "industries_transportation_subtitle",
    description: "industries_transportation_description",
    cover: classify,
    examples: [
      {
        id: "subway",
        includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
        name: "industries_transportation_examples_subway_name",
        title: "industries_transportation_examples_subway_title",
        description: "industries_transportation_examples_subway_description",
        video: crowd1_video,
        image: ""
      },
      {
        id: "road_crash",
        includes: ['Real-time detection', 'Notification', 'Classification', 'Anomalies'],
        name: "industries_transportation_examples_road_crash_name",
        title: "industries_transportation_examples_road_crash_title",
        description: "industries_transportation_examples_road_crash_description",
        video: crash_video,
        image: ""
      },
      {
        id: "vehicle_counting",
        name: "industries_transportation_examples_vehicle_counting_name",
        title: "industries_transportation_examples_vehicle_counting_title",
        description: "industries_transportation_examples_vehicle_counting_description",
        includes: ['Real-time counting', 'Data analysis', 'Classification'],
        image: classify1,
      },
    ]
  },
  {
    id: "smart_city",
    name: "industries_smart_city_name",
    label: "industries_smart_city_label",
    title: "industries_smart_city_title",
    subtitle: "industries_smart_city_subtitle",
    description: "industries_smart_city_description",
    aspects: ['Transportation', 'Crowd Analysis', 'Anomalies'],
    cover: parking,
    examples: [
      {
        id: "public_safety",
        name: "industries_smart_city_examples_public_safety_name",
        title: "industries_smart_city_examples_public_safety_title",
        description: "industries_smart_city_examples_public_safety_description",
        includes: ['Crowd Analysis', 'Anomaly Detection', 'Threat Identification'],
        video: abandoned_object_video
      },
      {
        id: "traffic_management",
        name: "industries_smart_city_examples_traffic_management_name",
        title: "industries_smart_city_examples_traffic_management_title",
        description: "industries_smart_city_examples_traffic_management_description",
        includes: ['Vehicle Counting', 'Congestion Analysis', 'Route Optimization'],
        tile: 'Efficient Traffic Management Powered by AI',
        image: queue1
      },
      {
        id: "environmental_monitoring",
        name: "industries_smart_city_examples_environmental_monitoring_name",
        title: "industries_smart_city_examples_environmental_monitoring_title",
        description: "industries_smart_city_examples_environmental_monitoring_description",
        includes: ['Fire Detection', 'Smoke Detection'],
        video: fireDetection,

      }

    ]

  },
  {
    id: "retail",
    name: "industries_retail_name",
    label: "industries_retail_label",
    title: "industries_retail_title",
    subtitle: "industries_retail_subtitle",
    description: "industries_retail_description",
    aspects: ['Crowd Analysis', 'Anomalies'],
    cover: heatmap,
    examples: [
      {
        id: "queue_management",
        name: "industries_retail_examples_queue_management_name",
        title: "industries_retail_examples_queue_management_title",
        description: "industries_retail_examples_queue_management_description",
        includes: ['People Counting', 'Queue Detection', 'Traffic Flow Analysis'],
        video: heatmapVideo
      },
      {
        id: "theft_prevention",
        name: "industries_retail_examples_theft_prevention_name",
        title: "industries_retail_examples_theft_prevention_title",
        description: "industries_retail_examples_theft_prevention_description",
        includes: ['Suspicious Behavior Detection', 'Unattended Items Detection'],
        video: stealing_video
      },
    ]

  },

  {
    id: "domestic",
    name: "industries_domestic_name",
    label: "industries_domestic_label",
    title: "industries_domestic_title",
    subtitle: "industries_domestic_subtitle",
    description: "industries_domestic_description",
    aspects: ['Intrusion Detection', 'Anomaly Recognition', 'Area Surveillance'],
    cover: intrusion,
    examples: [
      {
        id: "intrusion_detection",
        name: "industries_domestic_examples_intrusion_detection_name",
        title: "industries_domestic_examples_intrusion_detection_title",
        description: "industries_domestic_examples_intrusion_detection_description",
        includes: ['Real-time Alerts', 'Unauthorized Entry Detection'],
        video: vandalizing
      },
      {
        id: "anomaly_recognition",
        name: "industries_domestic_examples_anomaly_recognition_name",
        title: "industries_domestic_examples_anomaly_recognition_title",
        description: "industries_domestic_examples_anomaly_recognition_description",
        includes: ['Unusual Activity Detection', 'Safety Alerts'],
        video: home_anomalies
      },
    ]

  },
  // {
  //   id: "government",
  //   name: "industries_government_name",
  //   label: "industries_government_label",
  //   title: "industries_government_title",
  //   subtitle: "industries_government_subtitle",
  //   description: "industries_government_description",
  //   aspects: ['Transportation', 'Anomalies'],
  //   cover: people,
  //   examples: [
  //     {
  //       id: "subway",
  //       name: "industries_government_examples_subway_name",
  //       title: "industries_government_examples_subway_title",
  //       description: "industries_government_examples_subway_description",
  //       includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
  //     },
  //     {
  //       id: "road_crash",
  //       name: "industries_government_examples_road_crash_name",
  //       title: "industries_government_examples_road_crash_title",
  //       description: "industries_government_examples_road_crash_description",
  //       includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
  //     },
  //     {
  //       id: "vehicle_counting",
  //       name: "industries_government_examples_vehicle_counting_name",
  //       title: "industries_government_examples_vehicle_counting_title",
  //       description: "industries_government_examples_vehicle_counting_description",
  //       includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
  //     },
  //   ]
  // },
];

export const USE_CASES = [
  {
    id: "crowd_management",
    name: "crowd_management.name",
    title: "crowd_management.title",
    label: "crowd_management.label",
    description: 'crowd_management.description',
    industries: [{
      id: "smart_city",
      name: "Smart City"
    },
    {
      id: "health",
      name: "Health"
    },
    {
      id: "retail",
      name: "Retail"
    }
    ],
    includes: ['Counting', 'Tracking', 'Queues', 'Flow', 'Intrusion', 'Anomalies'],
    cover: people,
    examples: [
      crowd1,
      crowd3,
      crowd2,
    ]
  },
  {
    id: "animal_detection",
    name: "animal_detection.name",
    title: "animal_detection.title",
    label: "animal_detection.label",
    description: 'animal_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "government", name: "Government" },
      { id: "domestic", name: "Domestic" }
    ],
    examples: [
      animals1,
      animals2
    ],
    cover: animalDetection
  },
  {
    id: "abandoned_luggage",
    name: "abandoned_luggage.name",
    title: "abandoned_luggage.title",
    label: "abandoned_luggage.label",
    description: 'abandoned_luggage.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      abandoned_object2,
      abandoned_object1,
      abandoned_object3
    ],
    cover: abandoned_object
  },
  {
    id: "classify_vehicle",
    name: "classify_vehicle.name",
    title: "classify_vehicle.title",
    label: "classify_vehicle.label",
    description: 'classify_vehicle.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      classify1,
    ],
    cover: classify
  },
  {
    id: "wrong_way_direction",
    name: "wrong_way_direction.name",
    title: "wrong_way_direction.title",
    label: "wrong_way_direction.label",
    description: 'wrong_way_direction.description',
    industries: [
      { id: "transportation", name: "Transportation" }
    ],
    examples: [
      wrongWay2,
    ],
    cover: wrongWay
  },
  {
    id: "parking_lot",
    name: "parking_lot.name",
    title: "parking_lot.title",
    label: "parking_lot.label",
    description: 'parking_lot.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      parkingLot,
      parkingLot1,
      parkingLot2,
    ],
    cover: parking
  },
  {
    id: "queue_detection",
    name: "queue_detection.name",
    title: "queue_detection.title",
    label: "queue_detection.label",
    description: 'queue_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      queue2,
      queue1,
    ],
    cover: traffic
  },
  {
    id: "anomaly",
    name: "anomaly.name",
    title: "anomaly.title",
    label: "anomaly.label",
    description: 'anomaly.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
    ],
    examples: [
      stealing1,
      vandalism1,
    ],
    cover: stealing
  },


  // {
  //   id: "object_counting",
  //   name: "Object counting",
  //   label: "Automated object counting with vision AI",
  //   industries: [
  //     { id: "retail", name: "Retail" },
  //     { id: "domestic", name: "Domestic" }
  //   ],
  //   description: "Automated object counting with vision AI",
  //   examples: [
  //     "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2560&q=80",
  //     "https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80",
  //     "https://images.unsplash.com/photo-1518623489648-a173ef7824f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2762&q=80"
  //   ],
  //   cover: traffic
  // },
  {
    id: "weapon_detection",
    name: "weapon_detection.name",
    title: "weapon_detection.title",
    label: "weapon_detection.label",
    description: 'weapon_detection.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
      { id: "domestic", name: "Domestic" }
    ],
    examples: [
      weapon1,
      weapon2,
      weapon3
    ],
    cover: weapon
  },
  {
    id: "intrusion",
    name: "intrusion.name",
    title: "intrusion.title",
    label: "intrusion.label",
    description: 'intrusion.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" },
      // { id: "security", name: "Security" },
      { id: "retail", name: "Retail" },
      { id: "domestic", name: "Domestic" },
    ],
    examples: [
      intrusion1,
      intrusion2
    ],
    cover: intrusion
  },
  {
    id: "crash_detection",
    name: "crash_detection.name",
    title: "crash_detection.title",
    label: "crash_detection.label",
    description: 'crash_detection.description',
    industries: [
      { id: "transportation", name: "Transportation" },
      { id: "smart_city", name: "Smart City" }
    ],
    examples: [
      crash1,
      crash2,
    ],
    cover: road_accident
  },
  {
    id: "movement_heatmaps",
    name: "movement_heatmaps.name",
    title: "movement_heatmaps.title",
    label: "movement_heatmaps.label",
    description: 'movement_heatmaps.description',
    industries: [
      { id: "smart_city", name: "Smart City" },
      { id: "retail", name: "Retail" }
    ],
    examples: [
      heatmap2,
      heatmap1,
    ],
    cover: heatmap
  },
  {
    id: "env_monitoring_fire_detection",
    name: "env_monitoring_fire_detection.name",
    title: "env_monitoring_fire_detection.title",
    label: "env_monitoring_fire_detection.label",
    description: 'env_monitoring_fire_detection.description',
    industries: [
      // {id: "environment", name: "Environment"},
      { id: "smart_city", name: "Smart City" },
      { id: "transportation", name: "Transportation" },
      { id: "government", name: "Government" }
    ],
    cover: houseFire,
    examples: [
      fire2,
      fire1,
      fire3,
    ],
  }

]
//------------------------------------------------------------------------------

// export const EXAMPLES = [
//   {
//     id: 1,
//     name: 'Crowd Management',
//     includes: ['Counting', 'Tracking', 'Queues', 'Flow', 'Intrusion', 'Anomalies'],
//     title: 'Streamlined Crowd Management',
//     description: 'Our AI-powered crowd management solution offers a customizable and scalable platform to meet your unique needs. With advanced queue detection and counting capabilities, you can optimize operations and reduce wait times, all while ensuring safety with features such as intrusion and anomaly detection. Our system is fully customizable, allowing you to tailor it to your specific requirements and preferences, making it the ideal solution for a wide range of environments and events.'
//   },
//   {
//     id: 2,
//     name: 'Environment Analysis',
//     includes: ['Fire and smoke detection and tracking', 'Animal and flora detection, classification'],
//     title: 'Enhancing Environmental Awareness with Real-Time Computer Vision',
//     description: 'Our advanced computer vision technology enables real-time detection and analysis of environmental factors such as fire and smoke, and identification of animal and plant species for conservation purposes.'
//   },
//   {
//     id: 3,
//     name: 'Road Traffic Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Queues', 'Stopped', 'Slow', 'Smoke', 'Accidents'],
//     title: 'Not Another Ordinary Object Detector: Next-Level Road Traffic Control Technology',
//     description: 'Revolutionize road traffic control with our state-of-the-art AI technology, which goes beyond traditional object detection and classification to provide comprehensive monitoring of traffic conditions and incidents. Upgrade to our advanced AI-powered solution that can track, classify, and analyze traffic patterns in real-time, providing invaluable insights for traffic management and optimization.'
//   },
//   {
//     id: 4,
//     name: 'Subway & Rail Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
//     title: 'Transforming Subway & Rail Systems with Advanced AI Technology',
//     description: 'Leverage our innovative AI solutions to enhance subway and rail operations, improve safety, and optimize transportation efficiency. With capabilities such as counting, tracking, and crowd analysis, we offer a comprehensive solution for monitoring and managing subway and rail systems.'
//   },
//   {
//     id: 5,
//     name: 'Harbour and Offshore Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Safety'],
//     title: 'Streamlining Harbour and Offshore Operations with AI-Powered Technology',
//     description: 'Unlock the full potential of your harbour and offshore operations with our intelligent AI solutions. With capabilities such as counting, tracking, and safety monitoring, we provide a comprehensive system that enhances security and efficiency in maritime environments.'
//   },
//   {
//     id: 6,
//     name: 'Airport Control',
//     includes: ['Counting', 'Tracking', 'Classification', 'Crowd Analysis', 'Anomalies'],
//     title: 'Revolutionizing Airport Operations with AI-Driven Insights',
//     description: 'Take your airport operations to the next level with our cutting-edge AI solutions. With features such as counting, tracking, and crowd analysis, we provide the tools you need to enhance safety, streamline operations, and provide a better passenger experience.'
//   }
// ];


export const PREDICTIONS_MENU = [
  { name: "Backpack", active: true, id: "backpack", icon: Backpack },
  { name: "Handbag", active: true, id: "handbag", icon: Handbag },
  { name: "Walking", active: true, id: "walking", icon: Walking, },
  { name: "Running", active: true, id: "running", icon: Running, },
  { name: "Standing", active: true, id: "standing", icon: Standing, },
  { name: "Standing up", active: true, id: "standing_up", icon: StandingUp, },
  { name: "Sitting down", active: true, id: "sitting_down", icon: SittingDown, },
  { name: "Jumping", active: true, id: "jumping", icon: Jumping, },
  { name: "Riding", active: true, id: "riding", icon: Riding, },
  { name: "Lying", active: true, id: "lying", icon: Lying, },
  { name: "Throwing", active: true, id: "throwing", icon: Throwing, },
  { name: "Falling", active: true, id: "falling", icon: Falling, },
  { name: "Fighting", active: true, id: "fighting", icon: Fighting },
  { name: "Suitcase", active: true, id: "suitcase", icon: Suitcase },
  { name: "Person", active: true, id: "person", icon: PersonIcon },
  { name: "Car", active: true, id: "car", icon: CarIcon },
  { name: "Bus", active: true, id: "bus", icon: BusIcon },
  { name: "Truck", active: true, id: "truck", icon: TruckIcon },
  { name: "Train", active: true, id: "train", icon: TrainIcon },
  { name: "Bicycle", active: true, id: "bicycle", icon: Bicycle },
  { name: "Motorcycle", active: true, id: "motorcycle", icon: Motorcycle },
  { name: "Smoke", active: true, id: "smoke", icon: Smoke },
  { name: "Fire", active: true, id: "fire", icon: Fire },
]

export const PARTNERS = [
  Thales_Logo,
  bosh,
  CHCBeira_univ,
  CMA_Novo,
  Globalvia,
  Ascendi,
  Nos,
  avigilon,
  ibm_softinsa,
  milestone,
  prosegur,
  proav
]


type CompanyMenu = {
  title: string;
  items: {
    name: string;
    link: string;
    icon: IconType;
  }[];
}

export type UseCaseType = typeof USE_CASES[0];

export type IndustriesType = typeof INDUSTRIES[0];

export type IconType = keyof typeof ICONS;
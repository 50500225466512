import React from 'react';
import { ComponentStory, ComponentMeta } from '@storybook/react';
import { ContactUs } from '../pages';

export default {
  title: 'Pages/ContactUs',
  component: ContactUs,
  parameters: {
    layout: 'fullscreen',
  },

} as ComponentMeta<typeof ContactUs>;

const Template: ComponentStory<typeof ContactUs> = (args) => <ContactUs />;


export const Default = Template.bind({});

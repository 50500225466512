import React from 'react';
import { ComponentStory, ComponentMeta } from '@storybook/react';
import { UseCase } from '../pages';

export default {
  title: 'Pages/UseCase',
  component: UseCase,
  parameters: {
    layout: 'fullscreen',
  },

} as ComponentMeta<typeof UseCase>;

const Template: ComponentStory<typeof UseCase> = (args) => <UseCase />;


export const Default = Template.bind({});

import React from 'react';
//import rgbHex from 'rgb-hex';
import Switch from "react-switch";
import { useState, useEffect } from 'react';
//import variables from "./styles/app.module.scss";
import styles from './styles/settings_popup.module.scss';

const SettingsPopup = (props) => {

    //////////////////////////////////////////////////////////////////////////////////////////////////////
    // VARIABLES
    //////////////////////////////////////////////////////////////////////////////////////////////////////
    const language = localStorage.getItem('language');
    const [languageState, setLanguageState] = useState(false);

    //const chosen_colour = variables["--primary_colour"];
    //const switch_colour = chosen_colour.replace("rgba(", "").replace(" ", "").replace(" ", "").split(",");

    ////////////////////////////////////////////////////////
    // AUXILIARY FUNCTIONS
    ////////////////////////////////////////////////////////
    // -----------------------------------------------------
    // handle switch changes
    // -----------------------------------------------------
    const handleLanguageStateChange = nextChecked => {
        setLanguageState(nextChecked);
        change_language(nextChecked ? "en" : "pt");
    };

    const change_language = (selected_language) => {
        localStorage.setItem('language', selected_language);
        window.location.reload(true);
    }

    useEffect(() => {
        setLanguageState(language === "pt" ? false : true);
        // eslint-disable-next-line
    }, []);

    return (
        <div className = {styles.desktop_root}>
            <div className = {styles.desktop_root_inner} style = {{background: "transparent"}}>
                
                <div className = {styles.language_div}>
                    <div className = {styles.switch_div}>
                        <div className = {styles.vertical_align}>
                            <div className = {styles.switch}>
                                <Switch
                                    onChange = {handleLanguageStateChange}
                                    checked = {languageState}
                                    handleDiameter = {22}
                                    className = {styles.switch_style}
                                    height = {25}
                                    width = {55}
                                    offColor = "#f3f3f3"
                                    onColor = "#000000"
                                    offHandleColor = "#000000"
                                    onHandleColor = "#f3f3f3"
                                    checkedIcon = { // dark mode icon
                                            <div
                                            style = {{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                paddingRight: 2
                                            }}
                                            >
                                                <div className = {styles.switch_icon_text} style = {{color: "white"}}>EN</div>
                                            </div>
                                        }
                                    uncheckedIcon = { // dark mode icon
                                            <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                paddingRight: 2
                                            }}
                                            >
                                                <div className = {styles.switch_icon_text} style = {{color: "black"}}>PT</div>
                                            </div>
                                        }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsPopup;
import React from 'react';
import { ComponentStory, ComponentMeta } from '@storybook/react';
import { Navbar } from '../components';

export default {
  title: 'Components/Navbar',
  component: Navbar,
  parameters: {
    layout: 'fullscreen',
  },

} as ComponentMeta<typeof Navbar>;

const Template: ComponentStory<typeof Navbar> = (args) => <Navbar {...args} />;

export const Light = Template.bind({});
Light.args = {
  language: 'en',
  theme: "light"
};

export const Dark = Template.bind({});
Dark.args = {
  language: 'en',
  theme: "dark"
};

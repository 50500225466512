import { ReactNode, useEffect, useState } from 'react';
import { Button } from './Button';
import { motion } from 'framer-motion'

import feature1 from "../resources/images/home/feature_1.png"
import feature2 from "../resources/images/home/feature2.mp4"
import feature3 from "../resources/images/home/feature_3.png"
import { useScroll, useTransform } from 'framer-motion';
import { classNames } from '../utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

interface HomeGridSectionProps {
}
const FADE_LEFT = {
  initial: {
    opacity: 0,
    left: - 400
  },
  final: {
    opacity: 1,
    left: 0
  }
}

const FADE_RIGHT = {
  initial: {
    opacity: 0,
    right: - 400
  },
  final: {
    opacity: 1,
    right: 0
  }
}

const FADE_BOTTOM = {
  initial: {
    opacity: 0,
    bottom: - 400
  },
  final: {
    opacity: 1,
    bottom: 0
  }
}

const SCROLL_OFFSET = 659

const ANIMATE_ONCE = true

export function HomeGridSection({ }: HomeGridSectionProps) {
  const { scrollYProgress, scrollY } = useScroll()
  const [glowingElement, setGlowingElement] = useState<number>(3)
  const scale = useTransform(scrollYProgress, [0, 1], [1, 600]);
  const { t, i18n } = useTranslation();
  const history = useHistory();


  useEffect(() => {
    // scrollYProgress.on('change', (e) => {
    //   if (scrollY.get() >= 900 + SCROLL_OFFSET) {
    //     setGlowingElement(1)
    //   }
    //   if (scrollY.get() >= 1300 + SCROLL_OFFSET) {
    //     setGlowingElement(2)
    //   }
    //   if (scrollY.get() >= 2000 + SCROLL_OFFSET) {
    //     setGlowingElement(3)
    //   }
    // })

  }, [])

  const handleNavigate = () => {
    history.push("/solutions");

  }

  return (

    <section className="mt-28 w-full mx-auto max-w-7xl " >
      <motion.div
        className="w-full px-4 xl:px-0 flex relative items-center"
        transition={{
          duration: 0.3,
          delay: 0.3
        }}
        viewport={{ once: true }}
      >
        <motion.div
          initial={{
            height: 0
          }}
          whileInView={{
            height: 96
          }}
          transition={{
            duration: .6,
            delay: 0.1
          }}
          viewport={{ once: true }}
          className="bg-gradient-to-b from-primary-600 to-cyan-500  relative w-1 h-24"
        />

        <div className="ml-4" >
          <motion.h2
            initial={FADE_BOTTOM.initial}
            whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: true }}
            className="text-2xl sm:text-3xl font-bold tracking-tight text-white " >{t("home_grid_title")}
          </motion.h2>
        </div>

      </motion.div>

      <div>
        <div className="w-96 rounded-xl border border-gray-400 bg-white">
          {/* Feature 1 */}
          <motion.div
            // initial={FADE_BOTTOM.initial}
            // whileInView={FADE_BOTTOM.final}
            transition={{
              duration: 0.3,
              delay: 0.3
            }}
            viewport={{ once: ANIMATE_ONCE }}
            onAnimationComplete={definition => {
              setGlowingElement(1)
            }}

            className={
              classNames(
                "w-full md:max-w-[720px] md:max-h-[440px] justify-self-center bg-neutral-900 md:rounded-3xl p-10 pr-24 flex items-center justify-center relative",
                // glowingElement >= 1 && "glow"
              )}>
            {/* <div className="glow"></div> */}
            <div>
              <h2 className="text-xl sm:text-2xl font-bold tracking-tight text-white ">{t("Traffic analysis")}</h2>
              <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item1")}</p>
              <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item1.2")} </p>
              <ul className="text-base sm:text-lg font-semibold tracking-tight text-neutral-200 list-disc mt-2 pl-10">
                <li>{t("Traffic congestion")}</li>
                <li>{t("Accidents")}</li>
                <li>{t("other traffic-related incidents")}</li>
              </ul>

              <Button onClick={handleNavigate} className="mt-10 ml-10" >{t("View more")}</Button>
            </div>
          </motion.div>
        </div>
      </div>


      <div className="mt-16 grid grid-cols-1 gap-x-6 gap-y-16 lg:grid-cols-2 lg:gap-y-16 lg:gap-x-8 items-center relative z-10">
        {/* Feature 1 */}
        <motion.div
          // initial={FADE_BOTTOM.initial}
          // whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
            delay: 0.3
          }}
          viewport={{ once: ANIMATE_ONCE }}
          onAnimationComplete={definition => {
            setGlowingElement(1)
          }}

          className={
            classNames(
              "w-full md:max-w-[720px] md:max-h-[440px] justify-self-center bg-neutral-900 md:rounded-3xl p-10 pr-24 flex items-center justify-center relative",
              // glowingElement >= 1 && "glow"
            )}>
          {/* <div className="glow"></div> */}
          <div>
            <h2 className="text-xl sm:text-2xl font-bold tracking-tight text-white ">{t("Traffic analysis")}</h2>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item1")}</p>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item1.2")} </p>
            <ul className="text-base sm:text-lg font-semibold tracking-tight text-neutral-200 list-disc mt-2 pl-10">
              <li>{t("Traffic congestion")}</li>
              <li>{t("Accidents")}</li>
              <li>{t("other traffic-related incidents")}</li>
            </ul>

            <Button onClick={handleNavigate} className="mt-10 ml-10" >{t("View more")}</Button>
          </div>
        </motion.div>

        <motion.div
          //  initial={FADE_BOTTOM.initial}
          // whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.5,
          }}
          viewport={{ once: ANIMATE_ONCE }}
          className="hidden md:flex w-[100%] xl:w-[120%] max-w-[800px] z-10 justify-self-center" >
          <motion.img className=" w-full -ml-10 " src={feature1} alt="feature_1" />
        </motion.div>

        {/* Feature 2 */}
        <motion.div
          // initial={FADE_LEFT.initial}
          // whileInView={FADE_LEFT.final}
          transition={{
            duration: 0.3,
          }}
          viewport={{ once: ANIMATE_ONCE }}
          className="hidden md:flex w-[90%] lg:w-[100%] z-10 justify-self-center self-center" >
          {/* <img className=" w-full   z-20" src={feature2} alt="feature_2" /> */}
          <video className=" w-full rounded-xl z-20" src={feature2} loop muted autoPlay />
        </motion.div>
        <motion.div
          //  initial={FADE_BOTTOM.initial}
          // whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.3,
          }}
          viewport={{ once: ANIMATE_ONCE }}
          className={classNames(
            "w-full md:w-[720px] md:h-[440px] justify-self-center bg-neutral-900  md:rounded-3xl p-10 md:pl-24 flex items-center justify-center relative ",
            // glowingElement >= 2 && "glow"
          )} >



          <div>
            <h2 className="text-xl sm:text-2xl font-bold tracking-tight text-white ">{t("Crowd detection")}</h2>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item2")}</p>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item2.2")}</p>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item2.3")}</p>
            <ul className="text-base sm:text-lg font-semibold tracking-tight text-neutral-200 list-disc mt-2 pl-10">
              <li>{t("Indoor or outdoor detection")}</li>
              <li>{t("Social Distancing Monitoring")}</li>
              <li>{t("People counting")}</li>
            </ul>

            {/* <Button className="mt-10 ml-10" >View more</Button> */}
          </div>
        </motion.div>

        {/* Feature 3 */}
        <motion.div
          // initial={FADE_LEFT.initial}
          // whileInView={FADE_LEFT.final}
          transition={{
            duration: 0.3,
          }}
          viewport={{ once: ANIMATE_ONCE }}
          className={
            classNames(
              "w-full md:max-w-[720px] h-[420px] justify-self-center bg-neutral-900 md:rounded-3xl p-10 pr-24 flex items-center justify-center relative",
              // glowingElement === 3 && "glow"
            )
          }>
          {/* <div className="absolute h-64 w-[3px] bg-primary-500 -top-[250px] -z-10 " /> */}

          <div>
            <h2 className="text-xl sm:text-2xl font-bold tracking-tight text-white ">{t("Public safety")}</h2>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item3")}</p>
            <p className="text-base sm:text-lg  tracking-tight text-white mt-3 ">{t("home_grid_item3.2")}</p>


            <Button onClick={handleNavigate} className="mt-10 ml-10" >{t("Explore more")}</Button>
          </div>
        </motion.div>
        <motion.div
          initial={FADE_BOTTOM.initial}
          whileInView={FADE_BOTTOM.final}
          transition={{
            duration: 0.5,
          }}
          viewport={{ once: ANIMATE_ONCE }}
          className="hidden md:flex w-[100%] xl:w-[120%] max-w-[600px] z-10 justify-self-center" >
          <img className=" w-full " src={feature3} alt="feature_1" />
        </motion.div>

      </div>
    </section>

  );
}

import React from 'react';
import { ComponentStory, ComponentMeta } from '@storybook/react';
import { Solutions } from '../pages';

export default {
  title: 'Pages/Solutions',
  component: Solutions,
  parameters: {
    layout: 'fullscreen',
  },

} as ComponentMeta<typeof Solutions>;

const Template: ComponentStory<typeof Solutions> = (args) => <Solutions />;


export const Default = Template.bind({});

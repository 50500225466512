import { ReactNode, useEffect, useState } from 'react';
import { MoonIcon } from '@heroicons/react/24/solid'
import { classNames } from '../utils';
import { useDarkMode } from '../hooks/useTheme';
import styles from '../styles/settings_popup.module.scss';
import Switch from "react-switch";

interface DarkModeToggleProps {
}

export function DarkModeToggle({ }: DarkModeToggleProps) {
  // Initialize the theme based on user preference or OS setting
  const { theme, setTheme } = useDarkMode()

  const toggleTheme = () => {
    if (theme === 'dark') {
      setTheme('light');
      localStorage.theme = 'light';
    } else {
      setTheme('dark');
      localStorage.theme = 'dark';
    }
  };

  return (
    <>
      <div className={styles.switch_div}>
        <div className={styles.vertical_align}>
          <div className={styles.switch}>
            <Switch
              onChange={toggleTheme}
              checked={theme === "dark"}
              handleDiameter={22}
              className={styles.switch_style}
              height={25}
              width={55}
              offColor="#f3f3f3"
              onColor="#000000"
              offHandleColor="#000000"
              onHandleColor="#f3f3f3"
              checkedIcon={ // dark mode icon
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    paddingRight: 2
                  }}
                >
                  <div className={styles.switch_icon_text} style={{ color: "white" }}>
                    <MoonIcon className={classNames(
                      "w-5 h-5",
                      theme === "dark" ? "text-yellow-300" : ""
                    )} />
                  </div>
                </div>
              }
              uncheckedIcon={ // dark mode icon
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    paddingRight: 2
                  }}
                >
                  <div className={styles.switch_icon_text} style={{ color: "black" }}>
                    <MoonIcon className={classNames(
                      "w-5 h-5",
                      theme === "dark" ? "text-yellow-300" : ""
                    )} />
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </>

  );
}

// react
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
//------------------------------------------------------------------------------


interface DarkModeContextData {
  theme: string
  setTheme: React.Dispatch<React.SetStateAction<string>>
}

const DarkModeContext = createContext<DarkModeContextData>({} as DarkModeContextData);

export const useDarkMode = () => {
    return useContext(DarkModeContext);
};


interface DarkModeProviderProps {
  children: ReactNode;
}


export function DarkModeProvider({ children }:DarkModeProviderProps) {
  // Initialize the theme based on user preference or OS setting
  const [theme, setTheme] = useState(() => {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      return 'dark';
    } else {
      return 'light';
    }
  });

  useEffect(() => {
    if (theme === 'dark') {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [theme]);

  return (
    <DarkModeContext.Provider value={{theme, setTheme}}>
      {children}
    </DarkModeContext.Provider>
  );
}
